import { createSelectors as R } from "@waitroom/state";
import { uniqBy as E, parseJson as O } from "@waitroom/utils";
import { create as p } from "zustand";
import { useEffect as P, useCallback as m, useState as B, useRef as F, useMemo as A } from "react";
import { useLocalParticipant as J, usePreviewTracks as N } from "@livekit/components-react";
import { RoomEvent as d } from "livekit-client";
import { createSelector as j } from "reselect";
let i;
const Z = (t) => {
  i = t.logger;
}, x = 6, y = 5e3, D = () => ({
  lastReaction: void 0,
  reactions: {},
  aggregateReactions: {
    count: {},
    users: {}
  }
}), v = p()(D), l = {}, I = (t) => {
  const e = l[t];
  e && clearTimeout(e), l[t] = setTimeout(() => {
    S.setState((s) => ({
      ...s,
      aggregateReactions: {
        count: {
          ...s.aggregateReactions.count,
          [t]: void 0
        },
        users: {
          ...s.aggregateReactions.users,
          [t]: void 0
        }
      }
    }));
  }, y);
}, S = R(v, void 0, {
  resetState: () => {
    v.setState(D());
    const t = Object.values(l);
    for (let e = 0; e < t.length; e++)
      clearTimeout(t[e]);
  }
}), h = (t) => {
  S.setState((e) => ({ ...e, ...t }));
}, b = (t, e) => {
  S.setState((s) => {
    var a;
    const n = s.aggregateReactions, c = !e || s.reactions[e.id] === t ? s.reactions : {
      ...s.reactions,
      [e.id]: t
    }, o = ((a = n.count[t]) == null ? void 0 : a[0]) || 0;
    return {
      ...s,
      lastReaction: t,
      reactions: c,
      aggregateReactions: {
        count: { ...n.count, [t]: [o + 1, o] },
        users: e ? {
          ...n.users,
          [t]: E([e, ...n.users[t] || []], "id").slice(
            0,
            x
          )
        } : n.users
      }
    };
  }), I(t);
}, tt = (t) => {
  S.setState((e) => ({
    ...e,
    reactions: {
      ...e.reactions,
      [t]: void 0
    }
  }));
}, et = () => {
  const t = S.use.aggregateReactions();
  return P(() => () => {
    S.resetState();
  }, []), t;
}, T = () => ({
  offTheRecordStatus: "DISABLED",
  recordingStatus: ""
}), L = p()(T), k = R(L), st = (t) => {
  k.setState((e) => ({ ...e, ...t }));
}, at = () => k.setState(T()), U = new TextEncoder(), _ = new TextDecoder(), nt = (t) => {
  var n;
  const e = (n = J({ room: t })) == null ? void 0 : n.localParticipant;
  return {
    send: m(
      async (c, o) => {
        if (!e) return !1;
        const a = U.encode(JSON.stringify(c));
        return await e.publishData(a, { reliable: !0 }), o && o(c, e), !0;
      },
      [e]
    ),
    localParticipant: e
  };
}, ct = (t, e) => {
  P(() => {
    if (!e) return;
    const s = (n, c) => {
      const o = _.decode(n), a = JSON.parse(o);
      t(a, c);
    };
    return e.on(d.DataReceived, s), () => {
      e.off(d.DataReceived, s);
    };
  }, [e, t]);
}, it = (t, e) => {
  const [s, n] = B(), c = F(e);
  P(() => {
    c.current = e;
  }, [e]);
  const o = m((u) => {
    var r;
    n(u), (r = c.current) == null || r.call(c, u);
  }, []);
  return P(() => {
    n(void 0);
  }, [t.video, t.audio]), { tracks: N(t, o), error: s };
}, M = (t) => {
  const e = O(t);
  if (e)
    return {
      ...e,
      userOrGuest: e.user || (e.guest ? {
        id: e.guest.surrogateID,
        firstName: e.guest.fullName
      } : void 0)
    };
}, w = (t) => {
  if (t != null && t.metadata)
    return t.parsedMetadata = t.parsedMetadata || M(t.metadata), t.parsedMetadata;
}, $ = p()(() => ({
  participants: {}
})), g = R($), q = (t) => {
  g.setState((e) => ({
    ...e,
    participants: t
  }));
}, G = (t, e) => {
  g.setState((s) => ({
    ...s,
    participants: { ...s.participants, [t]: e }
  }));
}, X = (t) => g.setState((e) => {
  const { [t]: s, ...n } = e.participants;
  return {
    ...e,
    participants: n
  };
}), z = (t) => j(
  (e) => e.participants,
  (e) => e[t]
), ot = ({
  room: t,
  onMetadataChange: e
}) => (P(() => {
  if (!t) return;
  const s = (a, u) => {
    const r = u.metadata || a;
    i == null || i.log("Participant metadata updated", u.identity, r == null ? void 0 : r.length);
    const f = r ? M(r) : void 0;
    f && (u.parsedMetadata = f, G(u.identity, f), e == null || e(u.identity, f));
  }, n = () => {
    const a = [t.localParticipant, ...t.remoteParticipants.values()];
    i == null || i.log("On connect", a);
    const u = {};
    for (let r = 0; r < a.length; r++) {
      const f = a[r];
      u[f.identity] = w(f);
    }
    q(u);
  }, c = (a) => {
    i == null || i.log("Participant joined", a.identity), s(a.metadata, a);
  }, o = (a) => {
    i == null || i.log("Participant disconnected", a.identity), X(a.identity), e == null || e(a.identity, void 0);
  };
  return t.on(d.Connected, n), t.on(d.ParticipantMetadataChanged, s), t.on(d.ParticipantConnected, c), t.on(d.ParticipantDisconnected, o), () => {
    t.off(d.Connected, n), t.off(d.ParticipantMetadataChanged, s), t.off(d.ParticipantConnected, c), t.off(d.ParticipantDisconnected, o);
  };
}, [e, t]), null), rt = (t) => {
  const e = A(
    () => z(t.identity),
    [t.identity]
  );
  return g(e) || w(t);
};
export {
  b as addReaction,
  _ as decoder,
  U as encoder,
  w as getParticipantMetadata,
  Z as initConfig,
  D as initialState,
  i as logger,
  z as makeSelectorParticipantMetadata,
  M as parseParticipantMetadata,
  X as removeParticipant,
  tt as removeReaction,
  at as resetRoomState,
  G as updateParticipant,
  q as updateParticipants,
  h as updateReactionsState,
  st as updateRoomState,
  et as useAggregateEmojiReactions,
  rt as useParticipantMetadata,
  g as useParticipantsStore,
  it as usePreviewTracks,
  S as useReactionsStore,
  ct as useReceiveDataMessage,
  k as useRoomStore,
  nt as useSendDataMessage,
  ot as useSetupParticipantMetadata
};
