var O = Object.defineProperty;
var D = (e, t, n) => t in e ? O(e, t, { enumerable: !0, configurable: !0, writable: !0, value: n }) : e[t] = n;
var C = (e, t, n) => D(e, typeof t != "symbol" ? t + "" : t, n);
import { LOGGER_SERVICE_BRAID as v } from "@waitroom/models";
import { retryWithBackoff as I, parseJson as k, parseStream as U } from "@waitroom/utils";
let a;
const Y = (e) => {
  a = e.logger;
}, P = (e) => e ? `?${Object.keys(e).map((t) => `${t}=${encodeURIComponent(e[t])}`).join("&")}` : "";
class N {
  constructor() {
    C(this, "handlerRef", { id: -1 });
  }
  get handler() {
    return this.handlerRef.id;
  }
  set handler(t) {
    this.handlerRef.id = t;
  }
  clear() {
    clearTimeout(this.handlerRef.id);
  }
}
function L(e, t, n = new N()) {
  let r = !1;
  const s = () => {
    n.handler = setTimeout(() => {
      e(() => {
        r = !0, n.clear();
      }), r || s();
    }, t);
  };
  return s(), n;
}
var J = window.fetch, B = window.AbortController, A = window.Headers;
async function H(e, t = {}) {
  if (t = { ...t }, t.headers ? t.headers = new A(t.headers) : t.headers = new A(), t.version && console.assert(Array.isArray(t.version), "fetch(): `version` must be an array"), t.parents && console.assert(Array.isArray(t.parents), "fetch(): `parents` must be an array"), t.version && t.headers.set("version", t.version.map(JSON.stringify).join(", ")), t.parents && t.headers.set("parents", t.parents.map(JSON.stringify).join(", ")), t.subscribe && t.headers.set("subscribe", "true"), t.peer && t.headers.set("peer", t.peer), t.headers.set("X-Keep-Alive", "true"), t.cache = "no-cache", t.patches)
    if (console.assert(!t.body, "Cannot send both patches and body"), console.assert(typeof t.patches == "object", "Patches must be object or array"), Array.isArray(t.patches) || (t.patches = [t.patches]), t.patches.length === 1) {
      let i = t.patches[0];
      t.headers.set("Content-Range", `${i.unit} ${i.range}`), t.headers.set("Content-Length", `${new TextEncoder().encode(i.content).length}`), t.body = i.content;
    } else
      t.headers.set("Patches", t.patches.length), t.body = t.patches.map((i) => {
        var g = `content-length: ${new TextEncoder().encode(i.content).length}`, c = `content-range: ${i.unit} ${i.range}`;
        return `${g}\r
${c}\r
\r
${i.content}\r
`;
      }).join(`\r
`);
  var n = t.signal, r = new B();
  t.signal = r.signal, n && n.addEventListener("abort", () => r.abort()), a == null || a.logService(v, 5, "Client fetch", t);
  var s = await J(e, t);
  s.subscribe = u, s.subscription = { [Symbol.asyncIterator]: f };
  const d = s.headers.get("X-Keep-Alive");
  function u(i, g) {
    if (!s.ok) throw new Error("Request returned not ok status:", s.status);
    if (s.bodyUsed)
      throw new Error("This response's body has already been read", s);
    G(
      s.body,
      // Each time something happens, we'll either get a new
      // version back, or an error.
      (c, l) => {
        if (!l)
          i(c);
        else {
          const w = r.signal.aborted;
          if (r.abort(), g) g(l, w);
          else throw "Unhandled network error in subscription";
        }
      },
      d
    );
  }
  function f() {
    var i = !1, g = [], c = null, l = null;
    return {
      async next() {
        if (g.length > 0) return { done: !1, value: g.shift() };
        var w = new Promise((y, h) => {
          c = y, l = h;
        });
        i || (i = !0, u(
          (y) => c(y),
          (y) => l(y)
        ));
        var p = await w;
        return c = (y) => g.push(y), l = (y) => {
          throw y;
        }, { done: !1, value: p };
      }
    };
  }
  return s;
}
async function G(e, t, n) {
  const r = e.getReader(), s = j(t);
  let d;
  if (n) {
    const u = (parseInt(n) + 15) * 1e3;
    d = L(() => s.checkTimeout(u), 1e4), s.keepAlive = !0;
  }
  for (; ; ) {
    let u, f;
    try {
      const i = await r.read();
      u = i.done, f = i.value;
    } catch (i) {
      d && d.clear(), s.timedOut || t(null, i);
      return;
    }
    if (u) {
      d && d.clear(), s.timedOut || t(null, "Connection closed");
      return;
    }
    s.read(f);
  }
}
const j = (e) => ({
  // A parser keeps some parse state
  state: { input: [] },
  // And reports back new versions as soon as they are ready
  cb: e,
  // Timestamp of last input
  last: Date.now(),
  timedOut: !1,
  keepAlive: !1,
  // You give it new input information as soon as you get it, and it will
  // report back with new versions as soon as it finds them.
  read(t) {
    const n = t;
    if (this.last = Date.now(), !(this.keepAlive && n.length > 12 && n[0] === 88 && n[1] == 45 && n[2] === 75 && n[3] === 101 && n[4] === 101 && n[5] === 112 && n[6] === 45 && n[7] === 65 && n[8] === 108 && n[9] === 105 && n[10] === 118 && n[11] === 101))
      for (this.state.input.push(...t); this.state.input.length; ) {
        try {
          this.state = z(this.state);
        } catch (r) {
          this.cb(null, r);
          return;
        }
        if (this.state.result === "success")
          this.cb({
            version: this.state.version,
            parents: this.state.parents,
            body: this.state.body,
            patches: this.state.patches,
            // Output extra_headers if there are some
            extra_headers: T(this.state.headers)
          }), this.state = { input: this.state.input };
        else if (this.state.result === "error") {
          this.cb(null, this.state.message);
          return;
        }
        if (this.state.result == "waiting") break;
      }
  },
  checkTimeout(t) {
    const n = Date.now() - this.last;
    a == null || a.logService(v, 5, `Interval diff: ${n}`), n > t && (this.timedOut || (a == null || a.logService(v, 3, "Connection timed out"), e(null, "Connection timed out")), this.timedOut = !0);
  }
});
function z(e) {
  if (!e.headers) {
    const t = $(e.input);
    if (t.result === "error") return t;
    if (t.result === "waiting")
      return e.result = "waiting", e;
    e.headers = t.headers, e.version = e.headers.version, e.parents = e.headers.parents, e.input = t.input;
  }
  return F(e);
}
function $(e) {
  const t = X(e);
  if (!t) return { result: "waiting" };
  const n = t.header_string, r = n.length, s = {}, d = /(:?[\w-_]+):\s?(.*)\r?\n?/gy;
  let u, f = !1;
  for (; u = d.exec(n); )
    s[u[1].toLowerCase()] = u[2], d.lastIndex === r && (f = !0);
  return f ? ("version" in s && (s.version = JSON.parse("[" + s.version + "]")), "parents" in s && (s.parents = JSON.parse("[" + s.parents + "]")), "patches" in s && (s.patches = JSON.parse(s.patches)), e = t.remaining_bytes, { result: "success", headers: s, input: e }) : {
    result: "error",
    message: 'Parse error in headers: "' + JSON.stringify(n.substr(d.lastIndex)) + '"',
    headers_so_far: s,
    last_index: d.lastIndex,
    headers_length: r
  };
}
function R(e) {
  const t = e.match(/(\S+)( (.*))?/);
  return t && { unit: t[1], range: t[3] || "" };
}
function F(e) {
  const t = parseInt(e.headers["content-length"]);
  if (isNaN(t)) {
    if (e.headers.patches != null) {
      e.patches = e.patches || [];
      let n = e.patches[e.patches.length - 1];
      for (; !(e.patches.length === e.headers.patches && (e.patches.length === 0 || "content" in n)); ) {
        if ((!n || "content" in n) && (n = {}, e.patches.push(n)), !("headers" in n)) {
          const r = $(e.input);
          if (r.result === "error") return r;
          if (r.result === "waiting")
            return e.result = "waiting", e;
          n.headers = r.headers, e.input = r.input;
        }
        {
          if (!("content-length" in n.headers))
            return {
              result: "error",
              message: "no content-length in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          if (!("content-range" in n.headers))
            return {
              result: "error",
              message: "no content-range in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          const r = parseInt(n.headers["content-length"]);
          if (e.input.length < r)
            return e.result = "waiting", e;
          const s = R(n.headers["content-range"]);
          if (!s)
            return {
              result: "error",
              message: "cannot parse content-range in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          n.unit = s.unit, n.range = s.range, n.content = new TextDecoder("utf-8").decode(
            new Uint8Array(e.input.slice(0, r))
          ), n.extra_headers = T(n.headers), delete n.headers, e.input = e.input.slice(r);
        }
      }
      return e.result = "success", e;
    }
  } else {
    if (t > e.input.length)
      return e.result = "waiting", e;
    if (e.result = "success", e.headers["content-range"]) {
      const n = R(e.headers["content-range"]);
      if (!n)
        return {
          result: "error",
          message: "cannot parse content-range",
          range: e.headers["content-range"]
        };
      e.patches = [
        {
          unit: n.unit,
          range: n.range,
          content: new TextDecoder("utf-8").decode(
            new Uint8Array(e.input.slice(0, t))
          )
          // Question: Perhaps we should include headers here, like we do for
          // the Patches: N headers below?
          // headers: state.headers
        }
      ];
    } else
      e.body = new TextDecoder("utf-8").decode(
        new Uint8Array(e.input.slice(0, t))
      );
    return e.input = e.input.slice(t), e;
  }
  return {
    result: "error",
    message: "cannot parse body without content-length or patches header"
  };
}
function T(e) {
  const t = Object.assign({}, e), n = ["version", "parents", "patches", "content-length", "content-range"];
  for (let r = 0; r < n.length; r++) delete t[n[r]];
  if (Object.keys(t).length !== 0)
    return t;
}
function X(e) {
  let t = 0;
  for (; e[t] === 13 || e[t] === 10; )
    t++;
  if (t === e.length)
    return null;
  let n = t, r = 0;
  for (; n < e.length; ) {
    if (e[n] === 10 && e[n + 1] === 10) {
      r = 2;
      break;
    }
    if (e[n] === 10 && e[n + 1] === 13 && e[n + 2] === 10) {
      r = 3;
      break;
    }
    if (e[n] === 13 && e[n + 1] === 10 && e[n + 2] === 10) {
      r = 3;
      break;
    }
    if (e[n] === 13 && e[n + 1] === 10 && e[n + 2] === 13 && e[n + 3] === 10) {
      r = 4;
      break;
    }
    n++;
  }
  if (n === e.length)
    return null;
  const s = e.slice(t, n), d = new TextDecoder("utf-8").decode(new Uint8Array(s));
  return {
    remaining_bytes: e.slice(n + r),
    header_string: d
  };
}
const q = {
  method: "GET",
  shouldReconnect: !0,
  subscribe: !0,
  skipRetryErrorCodes: [404]
}, Q = (e) => (t) => typeof t == "object" && (e.includes(t.status) || e.includes(t.code)), Z = (e) => {
  var t, n;
  if (typeof e == "object" && (e != null && e.error))
    return Number((n = (t = e == null ? void 0 : e.error) == null ? void 0 : t.details) == null ? void 0 : n.errCode);
}, S = async (e) => {
  if (!(e != null && e.body)) return;
  const t = typeof e.body == "string" ? e.body : e.body instanceof ReadableStream ? await U(e.body) : void 0;
  return t ? k(t) : void 0;
}, K = {
  _client: H,
  _connections: {},
  _listeners: /* @__PURE__ */ new Set(),
  _emitEvent(e) {
    this._listeners.forEach((t) => t(e));
  },
  updateStatus(e, t) {
    const n = this._connections[e];
    n && (n.state.status = t);
  },
  config: { interceptors: {} },
  addListener(e) {
    return this._listeners.add(e), () => this._listeners.delete(e);
  },
  clearListeners() {
    return this._listeners.clear();
  },
  close: function(e) {
    const t = this._connections[e];
    t && (t.abort.abort(`Close called for ${e}`), this.updateStatus(e, "closed"), this._emitEvent({
      type: "close",
      key: e
    }), this._connections[e] && (this._connections[e].connection = void 0));
  },
  // TODO: try refactoring to reduce complexity and split this up into smaller parts
  fetch: async function(e) {
    const { onError: t, onRetry: n } = this.config.interceptors, r = { ...q, ...e }, s = r.key || r.url || "", d = `${r.baseURL}${r.url}${P(r.params)}`;
    return await new Promise(async (f, i) => {
      const g = () => new Promise(async (c, l) => {
        var m, x;
        if (!!((m = this._connections[s]) != null && m.connection)) {
          const o = `Connection already exists ${s}`;
          if (a == null || a.logService(v, 5, o), !r.shouldReconnect) return i(new Error(o));
          this.close(s);
        }
        const p = new AbortController();
        if (r.signal) {
          if (r.signal.aborted)
            return this.close(s), f(void 0);
          r.signal.addEventListener("abort", () => {
            this.close(s);
          });
        }
        const y = this._client(d, {
          ...r,
          signal: p.signal
        });
        this._connections[s] = {
          state: {
            status: "connecting",
            url: d,
            key: s,
            startTime: Date.now(),
            arguments: r
          },
          abort: p,
          connection: y
        };
        let h;
        try {
          h = await y, this._connections[s] && (this._connections[s].state.response = h), this.updateStatus(s, "connected"), this._emitEvent({
            type: "connection",
            key: s,
            data: { response: h, args: r }
          });
        } catch (o) {
          return p.signal.aborted ? i(o) : (this.updateStatus(s, "reconnecting"), l(o));
        }
        if (h.status && h.status > 399) {
          const o = await S(h), b = {
            code: h.code || h.status,
            data: h == null ? void 0 : h.data,
            success: !1,
            error: o || h
          };
          return n ? l(b) : i(b);
        }
        if (r._retry = !1, e.subscribe !== !1)
          h.subscribe(
            (o) => {
              var b;
              if (o.body) {
                r._retry = !1;
                const _ = k(o.body);
                return this._emitEvent({
                  type: "body",
                  key: s,
                  data: _
                }), (b = r.onFetched) == null || b.call(r, _), _ ? f(_) : i(o);
              }
              if (o.patches && (this._emitEvent({
                type: "patch",
                key: s,
                data: o.patches
              }), r.onPatch)) {
                const _ = o.patches.map(
                  (E) => ({
                    op: E.unit,
                    path: E.range,
                    value: E.content ? k(E.content) : void 0
                  })
                );
                r.onPatch(_);
              }
            },
            async (o, b) => (this._emitEvent({
              type: "error",
              key: s,
              data: { error: o, wasAborted: b }
            }), b ? (this.updateStatus(s, "closed"), f(void 0)) : (r.onError && await r.onError(r, o), this.updateStatus(s, "reconnecting"), l(o)))
          );
        else {
          if (!h.body) return i(void 0);
          const o = await S(h);
          return o ? (this._emitEvent({
            type: "body",
            key: s,
            data: o
          }), (x = r.onFetched) == null || x.call(r, o), o ? f(o) : i(h)) : i(void 0);
        }
      });
      try {
        await I(g, {
          // infinite retries
          ...r.retryOptions,
          retries: -1,
          shouldSkip: Q(r.skipRetryErrorCodes),
          onRetry: async (c, l) => {
            if (a == null || a.logService(v, 5, `Retry ${l}`, s), !n) return;
            if (!await n(r, c)) return i(c);
          }
        });
      } catch (c) {
        if (typeof c == "object" && c) {
          const p = await S(c.error);
          p && (c.error = p);
        }
        return this.updateStatus(s, "closed"), this._emitEvent({
          type: "error",
          key: s,
          data: c
        }), a == null || a.report(c), this.close(s), r.onError && await r.onError(r, c), !t || !await t(r, c) ? i(c) : await this.fetch(r);
      }
    });
  }
};
export {
  K as braidService,
  P as buildQueryString,
  Z as getDetailsErrorCode,
  Y as initConfig,
  Q as isErrorCode,
  a as logger,
  L as setIntervalWithTimeout
};
