import { format as u } from "date-fns";
import { VideoFileExtension as g, SocialKeys as w } from "@waitroom/models";
const F = (t) => t != null, d = (t) => typeof t == "function", S = d, T = (t) => S(t) ? t() : t, x = (t, ...e) => e.some((n) => typeof t === n), N = (t, e) => e in t, L = (t, e) => t instanceof e, j = (...t) => (e) => t.reduceRight((n, r) => r(n), e), U = (...t) => (e) => t.reduce((n, r) => r(n), e), v = (t) => (...e) => Promise.resolve(t(...e)), P = (t) => (e) => {
  try {
    return t();
  } catch (n) {
    return e ? e(n) : n;
  }
}, Y = (t) => async (e) => {
  try {
    return await t();
  } catch (n) {
    return e ? e(n) : n;
  }
}, k = async (t, e) => {
  var o;
  const n = {
    delay: 300,
    retries: 2,
    ...e
  };
  let r;
  for (let s = 1; s <= n.retries; s++)
    try {
      return await t();
    } catch (i) {
      if (r = i, (o = n.shouldSkip) != null && o.call(n, i)) throw i;
      s < n.retries && await new Promise((c) => setTimeout(c, (n.delay ?? 300) * s));
    }
  throw r;
}, C = (t, e) => {
  let n = Date.now();
  const r = async (o = 1) => {
    var c;
    const s = {
      initialDelay: 300,
      maxDelay: 5e3,
      retries: 3,
      resetTime: 1e4,
      // 10s
      ...e
    }, i = Date.now();
    i - n > s.resetTime && (o = 1), n = i;
    try {
      return await t();
    } catch (f) {
      if (s.onError && await s.onError(f), (c = s.shouldSkip) != null && c.call(s, f) || s.retries !== -1 && o >= s.retries) throw f;
      return s.onRetry && await s.onRetry(f, o), new Promise(
        (y) => setTimeout(
          async () => y(r(o + 1)),
          Math.min(s.initialDelay * 2 ** (o - 1), s.maxDelay)
        )
      );
    }
  };
  return r();
}, b = (t) => (e) => (...n) => t(n) ? e(n) : void 0, z = () => typeof window < "u", _ = (t, e) => {
  const n = {};
  if (!t) return n;
  for (let r = 0; r < t.length; r++) {
    const o = t[r];
    e ? e(n, o) : n[String(o)] = o;
  }
  return n;
}, q = (t, e) => t ? (Array.isArray(e) ? e : [e]).every((r) => t.includes(r)) : !1, H = (t, e) => t ? (Array.isArray(e) ? e : [e]).some((r) => t.includes(r)) : !1, I = (t, e, n = ", ") => {
  if (!t) return "";
  const r = d(e) ? e : (o) => String(o[e] ?? "");
  return t.map(r).filter(Boolean).join(n);
}, J = (t, e, n) => {
  if (!t) return "";
  const r = d(e) ? e : (c) => String(c[e] ?? ""), o = t.map(r).filter(Boolean);
  if (o.length <= 1) return o.join(", ");
  const s = [...o], i = s.pop();
  return `${s.join(", ")} ${n} ${i}`;
};
function V(t, e) {
  return [...t.filter((n) => e.indexOf(n) === -1), ...e.filter((n) => t.indexOf(n) === -1)];
}
function Z(t) {
  const e = Math.floor(Math.random() * t.length);
  return t[e];
}
function W(t, e) {
  const n = {}, r = [];
  for (const o of t) {
    const s = o[e];
    n[s] || (n[s] = !0, r.push(o));
  }
  return r;
}
const a = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], l = 1024, G = (t, e = 2) => {
  if (t === 0) return "0 B";
  const n = e < 0 ? 0 : e, r = Math.floor(Math.log(t) / Math.log(l));
  return `${parseFloat((t / l ** r).toFixed(n))} ${a[r]}`;
}, Q = (t, e = "B", n = "MB", r = 2) => {
  if (!t || e === n) return t;
  const o = r < 0 ? 0 : r, s = a.indexOf(n), i = a.indexOf(e);
  if (s < 0 || i < 0) return t;
  const c = s - i;
  return parseFloat((t / l ** c).toFixed(o));
}, h = /:00/, K = 864e5, X = 36e5, tt = 6e4, et = (t) => t ? new Date(t) : /* @__PURE__ */ new Date(), nt = (t, e, {
  separator: n = " — ",
  date: r = "EEEE, MMM d, yyyy",
  time: o = "h:mm a"
} = {}) => {
  const s = u(t, `${r}${n}${o}`).replace(h, ""), i = u(e, o).replace(h, "");
  return `${s} - ${i}`;
}, p = (t) => {
  const n = ~~((t < 0 ? 0 : t) / 1e3), r = ~~(n / 60), o = ~~(r / 60);
  return [n % 60, r % 60, o % 24, ~~(o / 24)];
}, m = ["s", "m", "h", "d"], E = (t, e = m) => {
  let n = "";
  for (let r = t.length - 1; r >= 0; r--) {
    const o = t[r], s = e[r];
    o > 0 && s && (n += `${o}${e[r]} `);
  }
  return n.trimEnd();
}, rt = (t, e = m) => {
  const n = p(t);
  return E(n, e);
}, ot = (t) => typeof t == "number" ? t * 1e3 : t, st = (t, e) => {
  const n = new Date(t), r = [`FREQ=${e}`];
  return e === "weekly" ? r.push(`BYDAY=${u(n, "EEEEEE")}`) : e === "monthly" ? r.push(`BYMONTHDAY=${u(n, "d")}`) : e === "yearly" && r.push(`BYMONTH=${u(n, "M")};BYMONTHDAY=${u(n, "d")}`), r.join(";").toUpperCase();
}, it = (t, e = ":") => {
  const [n, r, o, s] = p(t), i = [];
  let c = o;
  return s > 0 && (c += s * 24), c > 0 ? (i.push(String(c)), i.push(String(r).padStart(2, "0"))) : i.push(String(r)), i.push(String(n).padStart(2, "0")), i.join(e);
}, ct = (t, e, n, r) => {
  e.forEach((o) => {
    t.addEventListener(o, n, r);
  });
}, ut = (t, e, n, r) => {
  e.forEach((o) => {
    t.removeEventListener(o, n, r);
  });
}, ft = (t = "") => new DOMParser().parseFromString(t, "text/html").documentElement.textContent || t, at = (t, e, n, r = {}) => t.reduce((o, s) => {
  const i = n[s] ?? e[s];
  return o[s] = s in r || i === void 0 ? i : String(i), o;
}, {});
function lt(t) {
  try {
    return JSON.parse(t), !0;
  } catch {
    return !1;
  }
}
function dt(t, e) {
  if (!t) return e;
  try {
    return JSON.parse(t);
  } catch {
    return e;
  }
}
const $ = typeof navigator < "u" && "locks" in navigator, ht = (t, e, n = {}) => $ ? (...r) => navigator.locks.request(t, n, async () => await e(...r)) : e, pt = (t = 0, e = Number.MAX_SAFE_INTEGER) => Math.floor(Math.random() * (e - t + 1)) + t, mt = (t, e, n = "+ ") => t > e ? `${n}${e}` : `${t}`, yt = (t) => t < 1e3 ? `${t}` : t < 1e6 ? `${Number((t / 1e3).toFixed(1))}k` : `${Number((t / 1e6).toFixed(1))}m`, M = (t) => t != null && (typeof t == "string" || typeof t == "number"), gt = (t) => t !== null && typeof t == "object" && !Array.isArray(t), O = (t) => Object.keys(t);
function wt(t, ...e) {
  return e.length ? e.reduce((r, o) => {
    const { [o]: s, ...i } = r;
    return i;
  }, t) : t;
}
function St(t, e, n) {
  if (!t) return n;
  const o = (typeof e == "string" ? e.split(".") : e).reduce((s, i) => s && s[i] !== void 0 ? s[i] : void 0, t);
  return o !== void 0 ? o : n;
}
function B(t, e, n) {
  return O({ ...t, ...e }).reduce((r, o) => ((n ? n(t[o]) === n(e[o]) : t[o] === e[o]) || (r[o] = e[o]), r), {});
}
const Et = (t, e) => Object.entries(B(t, e)).length === 0, $t = (t, e) => {
  if (typeof t != "object" || typeof e != "object" || t === null || e === null)
    return t === e;
  const n = Object.keys(t), r = Object.keys(e);
  if (n.length !== r.length) return !1;
  for (const o of n)
    if (!Object.prototype.hasOwnProperty.call(e, o) || t[o] !== e[o])
      return !1;
  return !0;
}, Mt = (t, e) => {
  if (!t) return;
  const n = {};
  for (let r = 0; r < t.length; r++) {
    const o = t[r], s = typeof e == "function" ? e(o) : o[e], i = M(s) ? s : String(s);
    n[i] = (n[i] || []).concat(o);
  }
  return n;
}, Ot = (t, e = g.HLS) => {
  for (let n = t.length - 1; n > -1; n -= 1)
    if (t[n].endsWith(e))
      return t[n];
  return t[0];
}, Bt = async (t) => {
  var r;
  const e = (r = t == null ? void 0 : t.getReader) == null ? void 0 : r.call(t);
  if (!e) return;
  const n = await e.read();
  return new TextDecoder().decode(n.value);
}, At = (t, e, n = "...") => !t || t.length <= e ? t : `${t.substring(0, e)}${n}`, Dt = (t) => !!t && t.trim() !== "", Rt = (t) => !!t && String(t).toLowerCase() !== "false" && t !== "0", Ft = ({
  text: t,
  /** Lenght of the step for reducing the size */
  lengthStep: e,
  /** Size reduction for each step  */
  sizeStep: n = 0.1,
  /** Minimum size */
  min: r = 0.5
}) => {
  const o = (t || "").length, s = 1 - Math.floor(o / e) * n;
  return s < r ? r : s;
}, Tt = `  
`, xt = (t, e = "-") => t.join(e), Nt = (t = "") => t === "" ? "" : ` ${t}`, Lt = ({
  params: t,
  relative: e = !1
} = {}) => {
  const n = new URL(window.location.href);
  if (t)
    for (const r of Object.keys(t))
      n.searchParams.set(r, String(t[r]));
  return e ? `${n.pathname}${n.search}` : n.href;
}, jt = ({
  relative: t = !1,
  queryString: e = !0
} = {}) => {
  const n = new URL(window.location.href);
  return e || (n.search = ""), t ? `${n.pathname}${n.search}` : n.href;
}, Ut = (t) => t ? /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i.test(
  t
) : !1, A = new RegExp("^(?:[a-z+]+:)?//", "i"), vt = (t) => !!t && A.test(t), Pt = (t, e) => {
  const n = new URL(t);
  for (const r of Object.keys(e))
    n.searchParams.set(r, String(e[r]));
  return n.href;
};
function Yt(t) {
  return t && t.replace(/(wss?:\/\/|wss?:)/g, "").replace("/socket", "").replace("/websocket", "");
}
const kt = (t) => t ? t == null ? void 0 : t.reduce((e, n) => (n.platform === "email" || (e[n.platform] = n.url), e), {}) : void 0, Ct = (t) => t ? w.reduce((e, n) => {
  const r = t[n];
  return !r || n === "email" || e.push({
    platform: n,
    url: r
  }), e;
}, []) : void 0;
export {
  K as MS_DAY,
  X as MS_HOUR,
  tt as MS_MINUTE,
  Pt as addQueryParams,
  Et as areDeepEqual,
  _ as arrayToMap,
  ct as bindEvents,
  Lt as buildCurrentUrl,
  xt as buildKey,
  Ft as calcResponsiveSize,
  j as compose,
  q as containsAll,
  H as containsAny,
  Q as convertBytes,
  et as dateOrNow,
  ft as decodeHtmlEntities,
  B as diff,
  V as difference,
  m as durationSuffixDefaults,
  rt as durationToFormat,
  kt as flattenSocials,
  G as formatBytes,
  nt as fromTo,
  St as get,
  jt as getCurrentUrl,
  Yt as getDomainKeyFromUrl,
  it as getFormattedLength,
  Z as getRandomItem,
  pt as getRandomNumber,
  Ot as getRecordingURL,
  Mt as groupBy,
  b as ifThen,
  F as isDefined,
  S as isFn,
  d as isFunction,
  L as isInstanceOf,
  lt as isJsonString,
  Dt as isNotEmpty,
  gt as isObject,
  vt as isRelativeUrl,
  N as isType,
  x as isTypeof,
  M as isValidKey,
  Ut as isValidUrl,
  I as joinOn,
  J as joinWithAnd,
  O as keysOf,
  at as mergeValues,
  l as multiplier,
  $ as navigatorLockSupported,
  Tt as newline,
  wt as omit,
  T as orFn,
  dt as parseJson,
  p as parseMilliseconds,
  Bt as parseStream,
  E as parsedDurationToFormat,
  U as pipe,
  v as promisify,
  k as retry,
  C as retryWithBackoff,
  $t as shallowCompare,
  yt as shortenNumber,
  a as sizes,
  Rt as toBoolean,
  ot as toEpoch,
  mt as toLimit,
  st as toRRule,
  At as truncate,
  P as tryCatch,
  Y as tryCatchAsync,
  ut as unbindEvents,
  Ct as unflattenSocials,
  W as uniqBy,
  z as windowDefined,
  ht as withLock,
  Nt as withSpace
};
