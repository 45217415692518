import { Icon, IconProps, Image, ImageProps } from '@chakra-ui/react';
import { keysOf } from '@waitroom/utils';
import { ReactElement } from 'react';
import { BreakpointKeys, ResponsiveBreakpoints } from '../../../theme/breakpoints';
import { CDN_LOGO_URL, commonConfig } from '../../config';
import { logo, logoIconAnimated } from './icons';

export type Width<T = number> = T | ResponsiveBreakpoints<T>;

type CalcSizeResponse = { w: Width<string>; h: Width<string> };
const calcSize = (width: Width, baseWidth: number, baseHeight: number): CalcSizeResponse => {
  if (typeof width === 'number') {
    return { w: `${width}px`, h: `${baseHeight / (baseWidth / width)}px` };
  }
  return keysOf(width).reduce<{
    w: ResponsiveBreakpoints<string>;
    h: ResponsiveBreakpoints<string>;
  }>(
    (ac, key) => {
      ac.w[key] = `${width[key]}px`;
      ac.h[key] = `${baseHeight / (baseWidth / (width[key] || 0))}px`;
      return ac;
    },
    { w: {}, h: {} },
  );
};

export interface LogoIconProps extends IconProps {
  size?: Width;
  animate?: boolean;
}
export const LogoIcon = ({ size = 64, animate, ...rest }: LogoIconProps): ReactElement | null => (
  <Icon viewBox={animate ? '0 0 385 385' : '0 0 250 250'} {...calcSize(size, 250, 250)} {...rest}>
    {animate ? logoIconAnimated() : logo()}
  </Icon>
);

export interface LogoProps extends IconProps {
  size?: Width;
  textColor?: string;
  withText?: boolean;
}

export const Logo = ({
  size = 150,
  textColor,
  withText = true,
  ...rest
}: LogoProps): ReactElement | null => (
  <Icon viewBox="0 0 844 238" {...calcSize(size, 844, 238)} {...rest}>
    {logo(withText, textColor)}
  </Icon>
);

export const LogoImage = ({ dark, ...rest }: ImageProps & { dark?: boolean }): ReactElement => (
  <Image
    src={`${CDN_LOGO_URL}/${dark ? 'rumi-logo-white.svg' : 'rumi-logo.svg'}`}
    alt={commonConfig.company.name}
    htmlWidth={844}
    htmlHeight={238}
    display="inline-flex"
    {...rest}
  />
);

export const LogoIconImage = ({
  dark: _,
  ...rest
}: ImageProps & { dark?: boolean }): ReactElement => (
  <Image
    src={`${CDN_LOGO_URL}/rumi-logo-icon.svg`}
    alt={commonConfig.company.name}
    htmlWidth={256}
    htmlHeight={256}
    display="inline-flex"
    {...rest}
  />
);

export type ResponsiveLogoImageProps = {
  dark?: boolean;
  breakpoint?: BreakpointKeys;
  props?: ImageProps;
  iconProps?: ImageProps;
};
export const ResponsiveLogoImage = ({
  breakpoint = 'md',
  props,
  iconProps,
  dark,
}: ResponsiveLogoImageProps) => {
  return (
    <>
      <LogoIconImage
        display={{ base: 'initial', [breakpoint]: 'none' }}
        dark={dark}
        {...iconProps}
      />
      <LogoImage display={{ base: 'none', [breakpoint]: 'initial' }} dark={dark} {...props} />
    </>
  );
};
