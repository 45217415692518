import { createAction as T } from "@waitroom/react-utils";
import { useSelector as g, useDispatch as V } from "react-redux";
import Pe from "deepmerge";
import { produce as Ne } from "immer";
import { authApiService as S, authBaseEndpoint as se, httpService as Oe, braidService as Le, userApiService as B } from "@waitroom/common-api";
import { STORAGE_AUTH_TOKENS as H, LOGGER_SERVICE_AUTH as v, LOGGER_SERVICE_BRAID as j, ERROR_SESSION_ACCESS_UNAUTHORIZED_LOCKED as De } from "@waitroom/models";
import { tryCatchAsync as re, withLock as q, retry as Me, isFunction as ze } from "@waitroom/utils";
import { jwtDecode as Fe } from "jwt-decode";
import { useMemo as He } from "react";
import { useMutation as P } from "@tanstack/react-query";
import { isErrorCode as ce, getDetailsErrorCode as xe } from "@waitroom/braid";
import { bearerValue as b } from "@waitroom/http-client";
import { createListenerMiddleware as je } from "@reduxjs/toolkit";
import { getRequestData as W } from "@waitroom/react-query";
const $ = "refresh-token", oe = "auth/INIT_AUTH", ie = "auth/SETUP_AUTH", Ge = "auth/REFRESH_AUTH", ue = "auth/LOGOUT", ae = "auth/SET_CURRENT_USER", de = "auth/UPDATE_CURRENT_USER", Ot = "auth/SET_CURRENT_USER_SUBSCRIPTION_PLAN", fe = "auth/SET_AUTH_STATE", he = "auth/UPDATE_AUTH_STATE", le = "auth/SET_SUBSCRIPTION_PLAN", Te = "auth/REFRESH_SUBSCRIPTION_PLAN", ye = "auth/AUTHENTICATED", Ve = "auth/UN_AUTHENTICATED", me = "auth/GET_CURRENT_USER", Se = "auth/UPSERT_CURRENT_USER_LOBBY", Lt = T(oe), pe = T(ie), N = T(
  fe
), y = T(he), Be = T(me), Dt = T(
  ae
), Mt = T(de), qe = T(ue), O = T(ye), I = T(Ve), Ee = T(le), zt = T(Te), Ft = T(Se);
let a;
const Ht = (t) => {
  a = t.logger;
};
var We = (t, e, n) => {
  if (e.length === 1 && e[0] === n) {
    let s = !1;
    try {
      const r = {};
      t(r) === r && (s = !0);
    } catch {
    }
    if (s) {
      let r;
      try {
        throw new Error();
      } catch (o) {
        ({ stack: r } = o);
      }
      console.warn(
        `The result function returned its own inputs without modification. e.g
\`createSelector([state => state.todos], todos => todos)\`
This could lead to inefficient memoization and unnecessary re-renders.
Ensure transformation logic is in the result function, and extraction logic is in the input selectors.`,
        { stack: r }
      );
    }
  }
}, $e = (t, e, n) => {
  const { memoize: s, memoizeOptions: r } = e, { inputSelectorResults: o, inputSelectorResultsCopy: c } = t, i = s(() => ({}), ...r);
  if (!(i.apply(null, o) === i.apply(null, c))) {
    let d;
    try {
      throw new Error();
    } catch (f) {
      ({ stack: d } = f);
    }
    console.warn(
      `An input selector returned a different result when passed same arguments.
This means your output selector will likely run more frequently than intended.
Avoid returning a new reference inside your input selector, e.g.
\`createSelector([state => state.todos.map(todo => todo.id)], todoIds => todoIds.length)\``,
      {
        arguments: n,
        firstInputs: o,
        secondInputs: c,
        stack: d
      }
    );
  }
}, Ke = {
  inputStabilityCheck: "once",
  identityFunctionCheck: "once"
};
function Ye(t, e = `expected a function, instead received ${typeof t}`) {
  if (typeof t != "function")
    throw new TypeError(e);
}
function Ze(t, e = `expected an object, instead received ${typeof t}`) {
  if (typeof t != "object")
    throw new TypeError(e);
}
function Je(t, e = "expected all items to be functions, instead received the following types: ") {
  if (!t.every((n) => typeof n == "function")) {
    const n = t.map(
      (s) => typeof s == "function" ? `function ${s.name || "unnamed"}()` : typeof s
    ).join(", ");
    throw new TypeError(`${e}[${n}]`);
  }
}
var X = (t) => Array.isArray(t) ? t : [t];
function Qe(t) {
  const e = Array.isArray(t[0]) ? t[0] : t;
  return Je(
    e,
    "createSelector expects all input-selectors to be functions, but received the following types: "
  ), e;
}
function ee(t, e) {
  const n = [], { length: s } = t;
  for (let r = 0; r < s; r++)
    n.push(t[r].apply(null, e));
  return n;
}
var Xe = (t, e) => {
  const { identityFunctionCheck: n, inputStabilityCheck: s } = {
    ...Ke,
    ...e
  };
  return {
    identityFunctionCheck: {
      shouldRun: n === "always" || n === "once" && t,
      run: We
    },
    inputStabilityCheck: {
      shouldRun: s === "always" || s === "once" && t,
      run: $e
    }
  };
}, et = class {
  constructor(t) {
    this.value = t;
  }
  deref() {
    return this.value;
  }
}, tt = typeof WeakRef < "u" ? WeakRef : et, nt = 0, te = 1;
function w() {
  return {
    s: nt,
    v: void 0,
    o: null,
    p: null
  };
}
function ke(t, e = {}) {
  let n = w();
  const { resultEqualityCheck: s } = e;
  let r, o = 0;
  function c() {
    var R;
    let i = n;
    const { length: u } = arguments;
    for (let p = 0, C = u; p < C; p++) {
      const E = arguments[p];
      if (typeof E == "function" || typeof E == "object" && E !== null) {
        let m = i.o;
        m === null && (i.o = m = /* @__PURE__ */ new WeakMap());
        const _ = m.get(E);
        _ === void 0 ? (i = w(), m.set(E, i)) : i = _;
      } else {
        let m = i.p;
        m === null && (i.p = m = /* @__PURE__ */ new Map());
        const _ = m.get(E);
        _ === void 0 ? (i = w(), m.set(E, i)) : i = _;
      }
    }
    const d = i;
    let f;
    if (i.s === te)
      f = i.v;
    else if (f = t.apply(null, arguments), o++, s) {
      const p = ((R = r == null ? void 0 : r.deref) == null ? void 0 : R.call(r)) ?? r;
      p != null && s(p, f) && (f = p, o !== 0 && o--), r = typeof f == "object" && f !== null || typeof f == "function" ? new tt(f) : f;
    }
    return d.s = te, d.v = f, f;
  }
  return c.clearCache = () => {
    n = w(), c.resetResultsCount();
  }, c.resultsCount = () => o, c.resetResultsCount = () => {
    o = 0;
  }, c;
}
function st(t, ...e) {
  const n = typeof t == "function" ? {
    memoize: t,
    memoizeOptions: e
  } : t, s = (...r) => {
    let o = 0, c = 0, i, u = {}, d = r.pop();
    typeof d == "object" && (u = d, d = r.pop()), Ye(
      d,
      `createSelector expects an output function after the inputs, but received: [${typeof d}]`
    );
    const f = {
      ...n,
      ...u
    }, {
      memoize: R,
      memoizeOptions: p = [],
      argsMemoize: C = ke,
      argsMemoizeOptions: E = [],
      devModeChecks: m = {}
    } = f, _ = X(p), Ae = X(E), M = Qe(r), Z = R(function() {
      return o++, d.apply(
        null,
        arguments
      );
    }, ..._);
    let z = !0;
    const be = C(function() {
      c++;
      const F = ee(
        M,
        arguments
      );
      if (i = Z.apply(null, F), process.env.NODE_ENV !== "production") {
        const { identityFunctionCheck: J, inputStabilityCheck: Q } = Xe(z, m);
        if (J.shouldRun && J.run(
          d,
          F,
          i
        ), Q.shouldRun) {
          const we = ee(
            M,
            arguments
          );
          Q.run(
            { inputSelectorResults: F, inputSelectorResultsCopy: we },
            { memoize: R, memoizeOptions: _ },
            arguments
          );
        }
        z && (z = !1);
      }
      return i;
    }, ...Ae);
    return Object.assign(be, {
      resultFunc: d,
      memoizedResultFunc: Z,
      dependencies: M,
      dependencyRecomputations: () => c,
      resetDependencyRecomputations: () => {
        c = 0;
      },
      lastResult: () => i,
      recomputations: () => o,
      resetRecomputations: () => {
        o = 0;
      },
      memoize: R,
      argsMemoize: C
    });
  };
  return Object.assign(s, {
    withTypes: () => s
  }), s;
}
var _e = /* @__PURE__ */ st(ke), rt = Object.assign(
  (t, e = _e) => {
    Ze(
      t,
      `createStructuredSelector expects first argument to be an object where each property is a selector, instead received a ${typeof t}`
    );
    const n = Object.keys(t), s = n.map(
      (o) => t[o]
    );
    return e(
      s,
      (...o) => o.reduce((c, i, u) => (c[n[u]] = i, c), {})
    );
  },
  { withTypes: () => rt }
);
const ct = (t) => t.reduce(
  (e, n) => ({
    ...e,
    [n]: !0
  }),
  {}
), x = {
  userId: void 0,
  isAuthenticated: !1,
  status: "initialized",
  isGuest: !1
}, l = {
  _refreshPromise: void 0,
  _cache: {},
  config: {},
  init: function(t) {
    this.config = t;
  },
  getAnonymous: () => x,
  getDecodedToken: function(t) {
    if (this._cache[t]) return this._cache[t];
    const e = Fe(t);
    if (e)
      return this._cache[t] = e, e;
  },
  setup: async function(t, e = !0) {
    const n = t || this.getStorageTokens();
    if (!(n != null && n.accessToken)) return x;
    const s = this.decode(n, 600);
    s != null && s.accessToken && this.saveStorageTokens({
      accessToken: s.accessToken,
      refreshToken: s.refreshToken
    });
    const r = s || (e ? await this.refresh(n.refreshToken) : void 0);
    return r != null && r.accessToken ? r : this.logout();
  },
  getStorageTokens: function() {
    var t;
    return (t = this.config.storage) == null ? void 0 : t.getParsed(H);
  },
  saveStorageTokens: function(t) {
    var e;
    (e = this.config.storage) == null || e.set(H, t);
  },
  clearStorageTokens: function() {
    var t;
    (t = this.config.storage) == null || t.remove(H);
  },
  isTokenValid: function(t, e = 0) {
    const n = t ? this.getDecodedToken(t) : void 0;
    return !!n && !this.hasExpired(n.exp, e);
  },
  _refreshFn: async function(t) {
    var c;
    if (!t) return;
    const e = await re(
      () => S.refreshToken({
        data: {
          refreshToken: t
        }
      })
    )();
    if ((e == null ? void 0 : e.code) === 401) return;
    const { authToken: n, refreshToken: s } = ((c = e == null ? void 0 : e.data) == null ? void 0 : c.data) || {};
    if (!n) return;
    const r = { accessToken: n, refreshToken: s }, o = this.decode(r);
    if (o)
      return this.saveStorageTokens(r), o;
  },
  refresh: async function(t) {
    var r;
    if (a == null || a.logService(v, 1, "Refreshing auth token"), this._refreshPromise) return await this._refreshPromise;
    const e = (r = this.getStorageTokens()) == null ? void 0 : r.refreshToken, n = t || e;
    if (!n) return;
    this._refreshPromise = this._refreshFn(n);
    const s = await this._refreshPromise || await this._refreshFn(e);
    return a == null || a.logService(v, 1, "Refreshed auth token"), this._refreshPromise = void 0, s;
  },
  /** Decode token with expiration tolerance in seconds */
  decode: function(t, e) {
    const {
      userID: n,
      guestSurrogateID: s,
      guestEmail: r,
      guestFullName: o,
      exp: c,
      roleIDs: i = []
    } = this.getDecodedToken(t.accessToken) || {}, u = n || (s ? String(s) : void 0);
    if (!u || this.hasExpired(c, e)) return;
    const d = !n, f = {
      ...t,
      userId: u,
      isAuthenticated: !0,
      expires: c,
      agentId: u,
      isGuest: d,
      roles: ct(i),
      status: "initialized"
    };
    return d && (f.currentUser = {
      id: String(s),
      firstName: o || "",
      email: r || ""
    }), f;
  },
  logout: async function() {
    return await S.logout({ throwError: !1 }), this.clearStorageTokens(), x;
  },
  /** Check if token has expired with expiration tolerance in seconds */
  hasExpired: (t, e = 0) => !t || Date.now() + e * 1e3 > t * 1e3
}, ve = l.getAnonymous(), Ue = {
  ...ve,
  status: void 0
}, xt = (t = Ue, e) => Ne(t, (n) => {
  var s, r, o, c;
  switch (e.type) {
    case fe:
      return e.payload || ve;
    case he:
      return n = { ...n, ...e.payload }, n;
    case ae:
      return n.currentUser = e.payload, n.userId = (s = e.payload) == null ? void 0 : s.id, n;
    case de:
      return e.payload && (n.currentUser = Pe(
        n.currentUser || {},
        e.payload
      ), e.payload.id && (n.userId = e.payload.id)), n;
    case le:
      return !n.currentUser || !e.payload || (n.currentUser.subscriptionPlan = e.payload), n;
    case Se:
      {
        if (!n.currentUser || !e.payload) return n;
        let i = !1;
        n.currentUser.lobbies || (n.currentUser.lobbies = []);
        for (let u = 0; u < ((r = n.currentUser.lobbies) == null ? void 0 : r.length); u++)
          n.currentUser.lobbies[u].lobbyID === ((o = e.payload) == null ? void 0 : o.lobbyID) && (n.currentUser.lobbies[u] = e.payload, i = !0);
        i || (c = n.currentUser.lobbies) == null || c.push(e.payload);
      }
      return n;
    default:
      return t;
  }
}), h = (t) => t && t.auth ? t.auth : Ue, ot = (t) => h(t).isAuthenticated, it = (t) => h(t).isGuest, ut = (t) => h(t).status, jt = (t) => h(t).userId, Gt = (t) => {
  var e;
  return (e = k(t)) == null ? void 0 : e.firstName;
}, k = (t) => h(t).currentUser, Vt = (t) => {
  var e;
  return (e = k(t)) == null ? void 0 : e.id;
}, Bt = (t) => {
  var e;
  return (e = k(t)) == null ? void 0 : e.customerID;
}, qt = (t) => {
  var e;
  return (e = k(t)) == null ? void 0 : e.email;
}, at = (t) => h(t).roles, Re = (t) => {
  var e;
  return (e = k(t)) == null ? void 0 : e.notificationSettings;
}, Wt = (t) => {
  var e;
  return (e = k(t)) == null ? void 0 : e.onboarding;
}, $t = (t) => {
  var e;
  return (e = k(t)) == null ? void 0 : e.subscriptionPlan;
}, Kt = (t) => {
  var e;
  return (e = k(t)) == null ? void 0 : e.team;
}, Yt = (t) => {
  var e;
  return (e = Re(t)) == null ? void 0 : e.isPushNotificationEnabledGlobal;
}, Zt = (t) => {
  var e;
  return (e = Re(t)) == null ? void 0 : e.isEmailNotificationEnabledGlobal;
}, Jt = (t) => {
  const { accessToken: e, refreshToken: n, expires: s } = h(t);
  return { accessToken: e, refreshToken: n, expires: s };
}, Qt = (t) => h(t).accessToken, Xt = (t) => h(t).agentId, en = _e([k], (t) => {
  var e;
  if (t)
    return (e = t.lobbies) == null ? void 0 : e[0];
}), tn = () => {
  const t = g(h);
  return {
    state: t,
    isLoading: !t.status || t.status === "loading"
  };
}, dt = (t, e, n) => t ? n ? (typeof t == "string" ? [t] : t).reduce(
  (r, o) => {
    const c = !!e && !!e[o];
    return r[0][o] = c, r[1] = r[1] && c, r;
  },
  [{}, !0]
) : [{}, !1] : [{}, !0], nn = (t) => {
  const e = g(ot), n = g(it), s = g(ut), r = g(at), [o, c] = He(
    () => dt(t, r, e),
    [t, e, r]
  );
  return {
    isAuthenticated: e,
    isGuest: n,
    status: s,
    isLoading: !s || s === "loading",
    hasAccess: c,
    access: o,
    roles: r
  };
}, ft = (t) => ({
  ...t,
  mutationFn: async (e) => {
    const n = {
      ...e,
      email: e.email.toLowerCase()
    };
    return e.clientId && e.redirectUrl ? S.requestOAuthOTP({
      data: {
        ...n,
        redirect_uri: e.redirectUrl,
        client_id: e.clientId,
        state: e.state,
        codeChallenge: e.codeChallenge
      }
    }) : S.requestOTP({ data: n });
  }
}), ht = (t) => ({
  ...t,
  mutationFn: async ({ email: e, otp: n, clientId: s, state: r, codeChallenge: o }) => s ? S.verifyOAuthOTP({
    data: {
      email: e.toLowerCase(),
      otp: n,
      client_id: s,
      state: r,
      codeChallenge: o
    }
  }) : S.verifyOTP({
    data: {
      email: e.toLowerCase(),
      otp: n
    }
  })
}), lt = (t) => ({
  ...t,
  mutationFn: async ({
    token: e,
    code: n,
    type: s,
    userDetails: r,
    clientId: o,
    redirectUrl: c,
    state: i,
    codeChallenge: u
  }) => {
    if (!s || !n && !e) throw new Error("Invalid request");
    return o && c ? e ? S.verifyOAuthTokenProvider({
      data: {
        token: e,
        type: s,
        userDetails: r,
        client_id: o,
        redirect_uri: c,
        state: i,
        codeChallenge: u
      }
    }) : S.verifyOAuthCodeProvider({
      data: {
        code: n || "",
        type: s,
        client_id: o,
        redirect_uri: c,
        state: i,
        codeChallenge: u
      }
    }) : e ? S.verifyAuthTokenProvider({
      data: { token: e, type: s, userDetails: r }
    }) : S.verifyAuthCodeProvider({
      data: { code: n || "", type: s }
    });
  }
}), Tt = (t) => ({
  ...t,
  mutationFn: async (e) => S.logout(e)
}), sn = (t) => {
  const e = V();
  return P(
    Tt({
      ...t,
      onMutate: (...n) => {
        e(y({ status: "loading" })), t != null && t.onMutate && t.onMutate(...n);
      },
      onSuccess: (...n) => {
        e(y(l.getAnonymous())), e(I()), t != null && t.onSuccess && t.onSuccess(...n);
      }
    })
  );
}, rn = (t) => P(ft(t)), cn = ({ onRedirect: t, ...e }) => {
  const n = V();
  return P(
    lt({
      ...e,
      onMutate: (...s) => {
        n(y({ status: "loading" })), e != null && e.onMutate && e.onMutate(...s);
      },
      onError: (s, r, o) => {
        n(y({ status: "initialized" })), e != null && e.onError && e.onError(s, r, o);
      },
      onSuccess: (s, r, o) => {
        var i;
        if (n(y({ status: "initialized" })), !(s != null && s.success)) return;
        const c = (i = s.data) == null ? void 0 : i.data;
        if (c) {
          if (r.clientId && r.redirectUrl) {
            "redirect_uri" in c && c.redirect_uri && t(c.redirect_uri);
            return;
          }
          if ("authToken" in c && c.authToken) {
            const { authToken: u, refreshToken: d } = c;
            n(
              pe({
                accessToken: u,
                refreshToken: d
              })
            );
          }
          e != null && e.onSuccess && e.onSuccess(s, r, o);
        }
      }
    })
  );
}, on = ({ onRedirect: t, ...e }) => {
  const n = V();
  return P(
    ht({
      ...e,
      onMutate: (...s) => {
        n(y({ status: "loading" })), e != null && e.onMutate && e.onMutate(...s);
      },
      onSuccess: (s, r, o) => {
        var i;
        if (n(y({ status: "initialized" })), !(s != null && s.success)) return;
        const c = (i = s.data) == null ? void 0 : i.data;
        if (c) {
          if (r.clientId) {
            "redirect_uri" in c && c.redirect_uri && t(c.redirect_uri);
            return;
          }
          if ("authToken" in c && c.authToken) {
            const { authToken: u, refreshToken: d } = c || {};
            if (!u) return;
            const f = r.redirectUrl;
            n(
              pe({
                ...r,
                accessToken: u,
                refreshToken: d,
                onCompleted: f ? () => t(f) : void 0
              })
            );
          }
          e != null && e.onSuccess && e.onSuccess(s, r, o);
        }
      }
    })
  );
}, Ce = () => {
  const t = l.getStorageTokens();
  return t != null && t.accessToken ? [l.isTokenValid(t.accessToken, 30), t] : [!1, void 0];
}, yt = q($, async (t) => {
  a == null || a.logService(v, 3, "Refresh token interceptor lock");
  const [e, n] = Ce();
  if (e || !(n != null && n.refreshToken))
    return n == null ? void 0 : n.accessToken;
  const s = await l.refresh(n.refreshToken);
  if (s != null && s.accessToken)
    return t == null || t(s), s.accessToken;
}), L = async (t, e) => {
  const [n, s] = Ce();
  return n && t !== b(s.accessToken) ? s.accessToken : yt(e);
}, ge = ce([401]), ne = ce([404]), un = ({ onAuthUpdated: t }) => async (e, n) => {
  var s;
  if (a == null || a.logService(j, 3, "Error interceptor", e.key, n), !e._retry && ge(n)) {
    const r = await L((s = e.headers) == null ? void 0 : s.Authorization, t);
    return r ? (e._retry = !0, e.headers = {
      ...e.headers,
      Authorization: b(r)
    }, !0) : !1;
  }
  return !1;
}, an = ({ onAuthUpdated: t }) => async (e, n) => {
  var s;
  if (a == null || a.logService(j, 3, "Retry interceptor", e.key, n), ge(n)) {
    if (e._retry || xe(n) === De)
      return !1;
    const r = await L((s = e.headers) == null ? void 0 : s.Authorization, t);
    if (!r) return !1;
    e._retry = !0, e.headers = {
      ...e.headers,
      Authorization: b(r)
    };
  }
  return a == null || a.logService(j, 3, "is404", ne(n), n), !ne(n);
}, dn = ({ onRetry: t, onAuthUpdated: e }) => async (n) => {
  var r, o;
  t == null || t(n);
  const s = n == null ? void 0 : n.config;
  if (a == null || a.logLvl(3, "Axios auth interceptor", n), ((r = n == null ? void 0 : n.response) == null ? void 0 : r.status) === 401 && !(s != null && s._retry) && !String(s.url).startsWith(se)) {
    s._retry = !0;
    const c = (o = s.headers.Authorization) == null ? void 0 : o.toString(), i = await L(c, e);
    return a == null || a.logService(v, 3, "Axios auth interceptor"), i ? (s.headers.Authorization = b(i), Oe.client(s)) : Promise.reject(n);
  }
  return Promise.reject(n);
}, fn = ({ onRetry: t, onAuthUpdated: e }) => async (n, s) => {
  var c, i;
  t == null || t(s);
  const r = n;
  if ((s && typeof s == "object" ? (s == null ? void 0 : s.code) || (s == null ? void 0 : s.status) : s) === 401 && !(r != null && r.retried) && !String(r.url).startsWith(se)) {
    r.retried = !0;
    const u = (i = (c = r.headers) == null ? void 0 : c.Authorization) == null ? void 0 : i.toString(), d = await L(u, e);
    return d ? (r.headers = {
      ...r.headers,
      Authorization: b(d)
    }, Le.fetch(r)) : Promise.reject(s);
  }
  return Promise.reject(s);
}, K = (t) => !!t.isAuthenticated && !!t.userId && !t.isGuest, U = je();
let A;
const mt = (t, e) => new Promise(async (n) => {
  A && clearTimeout(A);
  const { expires: s, refreshToken: r } = t;
  if (!s || !r) return;
  const o = ~~(6e4 + Math.random() * 24e4), c = Math.max(0, s * 1e3 - Date.now() - o);
  a == null || a.logService(v, 3, "Refresh auth token setup timeout", {
    time: c
  }), A = setTimeout(
    q($, async () => {
      a == null || a.logService(v, 3, "Refresh auth token timeout");
      const u = await l.refresh(t.refreshToken) || await l.logout();
      return u && (e.dispatch(y(u)), e.dispatch(u.isAuthenticated ? O() : I())), n(u);
    }),
    c
  );
}), D = async (t, e) => {
  const n = await mt(t, e);
  return n != null && n.isAuthenticated && D(n, e), n;
}, St = 6e5;
let G;
const pt = (t) => new Promise(async (e) => {
  G = setTimeout(async () => {
    const n = await B.getSubscriptionPlan(t);
    return e(n);
  }, St);
});
async function Y(t) {
  G && clearTimeout(G);
  const e = h(t.getState());
  if (!K(e)) return;
  const n = await pt(e.userId), s = W(n);
  s && n.success && t.dispatch(Ee(s)), Y(t);
}
U.startListening({
  type: oe,
  effect: async (t, e) => {
    e.cancelActiveListeners();
    const n = await l.setup();
    n.refreshToken && e.fork(async () => {
      D(n, e);
    }), e.dispatch(N(n)), e.dispatch(n.isAuthenticated ? O() : I());
  }
});
U.startListening({
  type: me,
  effect: async (t, e) => {
    var o;
    e.cancelActiveListeners();
    const n = h(e.getState());
    if (!K(n)) return;
    const s = await re(
      () => Me(B.getCurrent, {
        retries: 2
      })
    )();
    if (s != null && s.code && s.code >= 400 && s.code <= 500) {
      e.dispatch(qe());
      return;
    }
    const r = (o = W(s)) == null ? void 0 : o.user;
    if (r) {
      const c = {
        id: r.userID,
        firstName: r.userFirstName,
        lastName: r.userLastName,
        ...r
      }, u = { ...h(e.getState()), currentUser: c };
      e.dispatch(N(u)), e.fork(() => Y(e));
      return;
    }
  }
});
U.startListening({
  type: ie,
  effect: async (t, e) => {
    e.cancelActiveListeners(), a == null || a.logService(v, 1, "SETUP_AUTH");
    const {
      accessToken: n,
      refreshToken: s,
      userId: r
    } = h(e.getState()) || {}, { accessToken: o, refreshToken: c, onCompleted: i } = t.payload || {};
    if (n === o && s === c) return;
    const u = await l.setup({
      accessToken: o,
      refreshToken: c
    });
    u.refreshToken && e.fork(() => D(u, e)), u.isAuthenticated && e.fork(() => Y(e)), r === u.userId ? e.dispatch(y(u)) : e.dispatch(N(u)), e.dispatch(O()), e.dispatch(y({ status: "initialized" })), i && (ze(i) ? i() : e.dispatch(i));
  }
});
U.startListening({
  type: ye,
  effect: async (t, e) => {
    e.cancelActiveListeners(), e.dispatch(Be());
  }
});
U.startListening({
  type: ue,
  effect: async (t, e) => {
    e.cancelActiveListeners(), e.dispatch(y({ status: "loading" })), A && clearTimeout(A), h(e.getState()).isAuthenticated && await l.logout(), e.dispatch(N(l.getAnonymous())), e.dispatch(I());
  }
});
U.startListening({
  type: Te,
  effect: async (t, e) => {
    e.cancelActiveListeners();
    const n = h(e.getState());
    if (!K(n)) return;
    const s = await B.getSubscriptionPlan(n.userId), r = W(s);
    r && s.success && e.dispatch(Ee(r));
  }
});
U.startListening({
  type: Ge,
  effect: async (t, e) => {
    e.cancelActiveListeners(), a == null || a.logService(v, 3, "Refreshing auth");
    const n = h(e.getState());
    q($, async () => {
      const r = await l.refresh(n.refreshToken) || await l.logout();
      r && (e.dispatch(y(r)), e.dispatch(r.isAuthenticated ? O() : I()), r != null && r.isAuthenticated && D(r, e));
    })();
  }
});
export {
  ye as AUTHENTICATED,
  me as GET_CURRENT_USER,
  oe as INIT_AUTH,
  ue as LOGOUT,
  Ge as REFRESH_AUTH,
  Te as REFRESH_SUBSCRIPTION_PLAN,
  ie as SETUP_AUTH,
  fe as SET_AUTH_STATE,
  ae as SET_CURRENT_USER,
  Ot as SET_CURRENT_USER_SUBSCRIPTION_PLAN,
  le as SET_SUBSCRIPTION_PLAN,
  Ve as UN_AUTHENTICATED,
  he as UPDATE_AUTH_STATE,
  de as UPDATE_CURRENT_USER,
  Se as UPSERT_CURRENT_USER_LOBBY,
  Ue as authInitialState,
  U as authListenerMiddleware,
  xt as authReducer,
  ve as authResetState,
  l as authService,
  O as authenticated,
  dn as axiosAuthInterceptor,
  fn as braidOnError,
  un as braidOnErrorInterceptor,
  an as braidOnRetryInterceptor,
  dt as checkAccess,
  L as getAccessToken,
  Be as getCurrentUser,
  Lt as initAuth,
  Ht as initConfig,
  a as logger,
  qe as logout,
  Tt as logoutMutation,
  zt as refreshSubscriptionPlan,
  $ as refreshTokenLockName,
  ft as requestOTPMutation,
  Xt as selectAgentId,
  Qt as selectAuthAccessToken,
  h as selectAuthState,
  ut as selectAuthStatus,
  Jt as selectAuthToken,
  jt as selectAuthUserId,
  k as selectCurrentUser,
  Bt as selectCurrentUserCustomerId,
  qt as selectCurrentUserEmail,
  Zt as selectCurrentUserEmailSetting,
  Gt as selectCurrentUserFirstName,
  Vt as selectCurrentUserId,
  en as selectCurrentUserLobby,
  Re as selectCurrentUserNotificationSettings,
  Wt as selectCurrentUserOnboarding,
  Yt as selectCurrentUserPushSetting,
  at as selectCurrentUserRoles,
  $t as selectCurrentUserSubscriptionPlan,
  Kt as selectCurrentUserTeam,
  ot as selectIsAuthenticated,
  it as selectIsCurrentUserGuest,
  N as setAuthState,
  Dt as setCurrentUser,
  Ee as setSubscriptionPlan,
  pe as setupAuth,
  I as unauthenticated,
  y as updateAuthState,
  Mt as updateCurrentUser,
  Ft as upsertCurrentUserLobby,
  tn as useAuth,
  nn as useHasAccess,
  sn as useLogout,
  rn as useRequestOTP,
  cn as useVerifyAuthProvider,
  on as useVerifyOTP,
  lt as verifyAuthProviderMutation,
  ht as verifyOTPMutation
};
