import { SessionAccessRuleStatus as F } from "@waitroom/models";
import { buildSuccessRequestResponse as W, aiApiService as k, calendarApiService as se, sessionApiService as d, cmsApiService as j, integrationsApiService as Y, lobbyApiService as v, meetingMemoryApiService as H, recordingApiService as z, sessionAccessApiService as b, authApiService as ne, userApiService as B, subscriptionPlanApiService as re, tokenApiService as oe, userMeetingTypesService as ie } from "@waitroom/common-api";
import { useQueryClient as J, useQuery as X, useInfiniteQuery as ae, QueryObserver as x } from "@tanstack/react-query";
import { braidService as ue } from "@waitroom/braid";
import { applyPatch as ye, deepClone as Ee } from "fast-json-patch";
import { useEffect as l, useMemo as _e, useRef as Se, useState as V, useCallback as ge } from "react";
const Z = (e) => typeof e == "function", P = ({
  client: e,
  key: t,
  fn: s,
  skipEmpty: n
}) => {
  const r = e.getQueryData(t);
  n && r === void 0 || e.setQueryData(t, s(r));
}, M = (e) => P({
  ...e,
  fn: (t) => ({
    code: 200,
    success: !0,
    ...t,
    data: Z(e.dataOrFn) ? e.dataOrFn(t == null ? void 0 : t.data) : e.dataOrFn
  })
}), ce = {
  update: M,
  addToArray: (e) => M({
    ...e,
    dataOrFn: (t) => [
      ...Array.isArray(e.data) ? e.data : [e.data],
      ...t || []
    ]
  }),
  updateInArray: ({
    data: e,
    keyId: t,
    ...s
  }) => M({
    ...s,
    dataOrFn: (n) => n == null ? void 0 : n.map((r) => r[t] === e[t] ? e : r)
  }),
  removeFromArray: ({
    id: e,
    keyId: t,
    ...s
  }) => M({
    ...s,
    dataOrFn: (n) => n == null ? void 0 : n.filter((r) => r[t] !== e)
  }),
  addToPages: (e) => P({
    ...e,
    fn: (t) => {
      var n, r, o;
      const s = {
        pageParams: [...(t == null ? void 0 : t.pageParams) || []],
        pages: [...(t == null ? void 0 : t.pages) || []]
      };
      return s.pages[0] = {
        ...s.pages[0],
        data: {
          code: 200,
          success: !0,
          ...(n = s.pages[0]) == null ? void 0 : n.data,
          data: e.updateFn((o = (r = s.pages[0]) == null ? void 0 : r.data) == null ? void 0 : o.data)
        }
      }, s;
    }
  }),
  updateInPages: (e) => P({
    ...e,
    fn: (t) => {
      var n, r;
      if (!t || !((n = t.pages) != null && n.length)) return t;
      const s = {
        pageParams: [...t.pageParams],
        pages: [...t.pages]
      };
      for (let o = 0; o < s.pages.length; o++) {
        const i = (r = s.pages[o]) == null ? void 0 : r.data, a = i == null ? void 0 : i.data;
        if (!a) continue;
        const y = e.updateFn(a);
        if (i !== y && (s.pages[o] = {
          ...s.pages[o],
          data: {
            ...i,
            data: y
          }
        }, e.stopOnChange))
          break;
      }
      return s;
    }
  })
}, me = (e) => P({
  ...e,
  fn: (t) => {
    var s;
    return {
      code: 200,
      success: !0,
      ...t,
      data: {
        code: 200,
        success: !0,
        ...t == null ? void 0 : t.data,
        data: Z(e.dataOrFn) ? e.dataOrFn((s = t == null ? void 0 : t.data) == null ? void 0 : s.data) : e.dataOrFn
      }
    };
  }
}), Ce = {
  update: me
}, m = {
  update: P,
  request: ce,
  apiRequest: Ce
}, U = "private", h = "calendarEvent", pe = "cmsPage", Ae = "cmsPages", Kt = "currentUser", le = "dashboardPastSessions", de = "dashboardSessions", Tt = "featuredSessions", qt = "integrationsConnections", Ft = "integrationsDirectURL", Pt = "integrationsGetAction", Re = "integrationsProviders", Oe = "meetingMemoryMessages", bt = "meetingMemorySuggestions", fe = "meetingMemoryThread", Ke = "meetingMemoryThreads", Te = "paymentDetails", qe = "recording", Dt = "recordingStatus", Mt = "session", Fe = "session-v2", Qt = "sessionAccessRule", I = "sessionAccessRules", Pe = "sessionAiFeed", be = "sessionEndSummary", De = "sessionEpisodes", Nt = "sessionPendingRulesStats", Me = "sessionPresence", ee = "sessionRequestAccess", Yt = "sessionViewers", Qe = "sessionCards", Ne = "streamToken", Ye = "listeningModeSpeakers", ve = "subscriptionPlans", He = "subscriptionReceipts", Be = "user", Ge = "integrationsGetByOwnerSessionRecurrence", Ie = "userMeetingTypes-v2", vt = "branch", Ht = "salesforceProvider", Bt = "salesforceGetBindingById", Gt = "salesforceGetAccountById", It = "salesforceGetOpportunityById", Lt = "salesforceListBindableRecordsAsOptions", kt = "salesforceGetContacts", Ut = "salesforceGetLeadById", $t = "hubspotProvider", wt = "hubspotGetBindingById", Le = "hubspotGetCompanyById", xt = "hubspotGetDealById", Vt = "hubspotListBindableRecordsAsOptions", Wt = "hubspotGetContacts", jt = "hubspotGetContactById", ke = "lobbyEnter", zt = (e, t) => [U, ...e, t], Q = (e, t = "") => [
  Pe,
  e,
  t
], L = (e, t = "") => [
  ee,
  e,
  t,
  F.IN_REVIEW
], Ue = (e, t, s) => [ee, e, t, s || ""], $e = (e, t, s) => [Fe, e, t || "", s || ""], we = (e, t = "") => [
  Me,
  e,
  t
], xe = (e) => [Be, e], $ = (e) => [
  Ke,
  e
], Jt = (e) => [fe, e], q = (e) => [
  Oe,
  e
], Xt = (e, t) => [
  Ye,
  e,
  t
], Ve = (e, t) => [
  be,
  e,
  t
], We = (e) => [ke, e], je = {
  add: ({ client: e, data: t, sessionId: s, recurrenceId: n }) => {
    t && m.request.addToArray({
      client: e,
      key: Q(s, n),
      data: [t]
    });
  },
  update: ({ client: e, data: t, sessionId: s, recurrenceId: n }) => {
    t && m.request.update({
      client: e,
      key: Q(s, n),
      dataOrFn: (r) => r ? (r.messages = r.messages.map((o) => o.id === t.id ? t : o), r) : { messages: [t], totalSummaryCount: 1 }
    });
  },
  remove: ({ client: e, sessionId: t, recurrenceId: s, id: n }) => {
    m.request.removeFromArray({
      client: e,
      key: Q(t, s),
      id: n,
      keyId: "id"
    });
  }
}, Zt = {
  ...je
}, ze = {
  addMessage: ({ client: e, data: t, id: s }) => {
    t && m.apiRequest.update({
      client: e,
      key: q(s),
      dataOrFn: (n) => ({
        hasMore: !1,
        ...n,
        messages: [...(n == null ? void 0 : n.messages) || [], t],
        total: ((n == null ? void 0 : n.total) || 0) + 1
      })
    });
  },
  updateMessage: ({ client: e, data: t, id: s }) => {
    t && m.apiRequest.update({
      client: e,
      key: q(s),
      dataOrFn: (n) => n != null && n.messages ? {
        ...n,
        messages: n.messages.map((r) => r.id === t.id ? { ...r, ...t } : r)
      } : n
    });
  },
  updateLastAiMessage: ({ client: e, data: t, id: s }) => {
    t && m.apiRequest.update({
      client: e,
      key: q(s),
      dataOrFn: (n) => {
        var y;
        const r = n && {
          ...n,
          messages: n.messages ? [...n.messages] : void 0
        };
        if (!(r != null && r.messages)) return n;
        const o = r.messages.findLastIndex(
          (c) => (!t.id || c.id === t.id) && c.role === "ai"
        );
        if (o === -1) return n;
        const i = r.messages[o], a = { ...i, ...t, id: t.id || i.id };
        return t.content && (a.content = `${i.content || ""}${t.content}`), t.todo && (a.todo = `${i.todo || ""}${t.todo}`), t.progress && (a.progress = `${i.progress || ""}${t.progress}`), r.messages[o] = a, !((y = a.content) != null && y.length) && t.isComplete && r.messages.splice(o, 1), r;
      }
    });
  },
  removeMessage: ({ client: e, id: t, messageId: s }) => {
    m.apiRequest.update({
      client: e,
      key: q(t),
      dataOrFn: (n) => {
        var i;
        if (!(n != null && n.messages)) return n;
        const r = n.messages.length, o = (i = n.messages) == null ? void 0 : i.filter((a) => a.id !== s);
        return {
          ...n,
          messages: o,
          total: Math.max((n.total || 0) - (r !== o.length ? 1 : 0), 0)
        };
      }
    });
  },
  removeLastMessage: ({ client: e, id: t }) => {
    m.apiRequest.update({
      client: e,
      key: q(t),
      dataOrFn: (s) => s != null && s.messages ? {
        ...s,
        messages: s.messages.slice(0, -1),
        total: Math.max((s.total || 0) - 1, 0)
      } : s
    });
  },
  removeThread: ({ client: e, id: t, userId: s }) => {
    m.apiRequest.update({
      client: e,
      key: $(s),
      dataOrFn: (n) => {
        var i;
        if (!(n != null && n.threads)) return n;
        const r = n.threads.length, o = (i = n.threads) == null ? void 0 : i.filter((a) => a.id !== t);
        return {
          ...n,
          threads: o,
          total: Math.max((n.total || 0) - (r !== o.length ? 1 : 0), 0)
        };
      }
    });
  }
}, ht = ze;
let O;
const es = (e) => {
  O = e.logger;
}, Je = (e) => {
  var t;
  return (t = e == null ? void 0 : e.data) == null ? void 0 : t.data;
}, ts = (e) => Je(e.data), ss = (e, t = 0) => {
  var s, n, r;
  return (r = (n = (s = e.data) == null ? void 0 : s.pages[t]) == null ? void 0 : n.data) == null ? void 0 : r.data;
}, Xe = (e, t, s = void 0, ...n) => {
  try {
    return ye(e, t, ...n).newDocument;
  } catch (r) {
    return O == null || O.logLvl(3, r, e, t), O == null || O.report(r), s;
  }
}, ns = (e, t, s) => {
  if (e.op !== "add" || !e.path.endsWith("/-")) return !0;
  const n = e.path.split("/").slice(1, -1);
  let r = s;
  for (const o of n)
    r[o] === null && (r[o] = []), r = r[o];
  return !0;
}, rs = (e) => {
  const t = J(), { queryFn: s, queryKey: n, braidKey: r } = e, o = X({
    ...e,
    queryFn: typeof s == "function" ? async (...i) => {
      const a = await s(...i);
      return t.getQueryData(n) ?? a;
    } : void 0
  });
  return l(() => () => {
    ue.close(r);
  }, [r]), o;
}, Ze = (e, t, s) => {
  t.setQueryData(s, e);
}, he = (e, t, s) => {
  t.setQueryData(
    s,
    (n = W()) => Xe(Ee(n), e) ?? n
  );
}, f = (e, t) => ({
  onFetched: (s) => Ze(s, t, e),
  onPatch: (s) => he(s, t, e)
}), os = {
  refetchOnMount: !1,
  refetchOnReconnect: !1,
  refetchOnWindowFocus: !1
}, w = (e) => (t) => ({
  gcTime: t,
  staleTime: t,
  retry: 2,
  refetchOnMount: !0,
  refetchOnReconnect: e,
  refetchOnWindowFocus: e
}), u = {
  "7d": 6048e5,
  "3d": 2592e5,
  "1d": 864e5,
  "6h": 216e5,
  "1h": 36e5,
  "30m": 18e5,
  "15m": 9e5,
  "10m": 6e5,
  "5m": 3e5,
  "3m": 18e4,
  "1m": 6e4,
  "30s": 3e4,
  "15s": 15e3,
  "5s": 5e3,
  "3s": 3e3,
  "1s": 1e3
}, E = [""], A = () => {
}, S = w(!0), D = {
  "7d": S(u["7d"]),
  "3d": S(u["3d"]),
  "1d": S(u["1d"]),
  "6h": S(u["6h"]),
  "1h": S(u["1h"]),
  "30m": S(u["30m"]),
  "15m": S(u["15m"]),
  "10m": S(u["10m"]),
  "5m": S(u["5m"]),
  "3m": S(u["3m"]),
  "1m": S(u["1m"]),
  "30s": S(u["30s"]),
  "15s": S(u["15s"]),
  "3s": S(u["3s"]),
  0: S(0)
}, g = w(!1), C = {
  "7d": g(u["7d"]),
  "3d": g(u["3d"]),
  "1d": g(u["1d"]),
  "6h": g(u["6h"]),
  "1h": g(u["1h"]),
  "30m": g(u["30m"]),
  "15m": g(u["15m"]),
  "10m": g(u["10m"]),
  "5m": g(u["5m"]),
  "3m": g(u["3m"]),
  "1m": g(u["1m"]),
  "30s": g(u["30s"]),
  "15s": g(u["15s"]),
  "3s": g(u["3s"]),
  0: g(0)
}, R = (e = 0) => ({
  ...w(!1)(e),
  refetchOnReconnect: !0
}), is = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: s,
  options: n,
  params: r
}) => {
  const o = !!e, i = o ? Q(e, t) : E, a = `get-session-ai-feed-${e}-${t}`;
  return {
    ...R(0),
    ...n,
    queryKey: i,
    braidKey: a,
    enabled: o,
    queryFn: o ? ({ signal: y }) => k.getFeed(e, t, {
      ...f(i, s),
      ...r,
      key: a,
      signal: y,
      params: {
        limit: 200,
        sortOrder: "asc",
        ...r == null ? void 0 : r.params
      }
    }) : A
  };
}, as = ({
  sessionId: e,
  recurrenceId: t,
  options: s,
  params: n
}) => ({
  ...s,
  mutationFn: (r) => k.event(e, t, {
    ...n,
    data: r
  })
}), us = ({
  sessionId: e,
  recurrenceId: t
}) => ({
  queryKey: Ve(e, t),
  queryFn: () => k.getSummary(e, t),
  ...C["5m"]
}), ys = (e) => [h, e], Es = (e) => ({
  queryKey: [h, e],
  queryFn: () => se.getCalndrLinkEvent({ data: e })
}), et = (e, t) => [
  Qe,
  e,
  t
], _s = ({
  sessionId: e,
  sessionRecurrenceId: t,
  queryClient: s,
  options: n
}) => {
  const r = !!e && !!t, o = r ? et(e, t) : E, i = `get-cms-cards-${e}-${t}`;
  return {
    ...R(0),
    queryKey: o,
    braidKey: i,
    enabled: r,
    queryFn: r ? () => d.cards(e, t, {
      ...f(o, s),
      key: i
    }) : A,
    ...n
  };
}, tt = (e) => [pe, e], Ss = ({
  slug: e,
  isPreview: t
}) => ({
  queryKey: e ? tt(e) : E,
  queryFn: () => j.getPage(e || ""),
  ...C[t ? 0 : "5m"],
  enabled: !!e
}), st = (e) => [Ae, e], gs = ({
  types: e,
  isPreview: t
}) => ({
  queryKey: st(e),
  queryFn: async () => {
    var o;
    const s = await j.getPages(e);
    if (!((o = s == null ? void 0 : s.data) != null && o.result)) throw s;
    const r = s.data.result.reduce(
      (i, a) => a.type ? a.type === "general" ? (a.category && (i.general[a.category] = [...i[a.type][a.category] || [], a]), i) : (i[a.type] = [...i[a.type] || [], a], i) : i,
      {
        general: {
          // here because of the order
          features: [],
          solutions: [],
          compare: [],
          resources: [],
          product: [],
          none: []
        }
      }
    );
    return {
      ...s,
      grouped: r
    };
  },
  ...C[t ? 0 : "15m"],
  gcTime: t ? 0 : 864e5
  // 1 day
}), nt = (e, t) => [Ge, e, t], cs = ({
  sessionId: e,
  recurrenceId: t,
  queryClient: s,
  options: n,
  params: r
}) => {
  const o = !!e && !!t, i = o ? nt(e, t) : E, a = `crmBindings-${e}-${t}`;
  return {
    queryKey: i,
    braidKey: a,
    enabled: o,
    ...R(0),
    ...n,
    queryFn: o ? () => Y.getBindingsByOwnerSessionRecurrencePerProvider(
      e,
      t,
      {
        key: a,
        ...f(i, s),
        ...r
      }
    ) : A
  };
}, ms = ({
  params: e,
  ...t
}) => ({
  ...t,
  mutationFn: ({
    sessionId: s,
    sessionRecurrenceId: n,
    record: { id: r, type: o }
  }) => Y.hubspot.createBinding({
    ...e,
    data: {
      sessionId: s,
      sessionRecurrenceId: n,
      record: {
        id: r,
        type: o
      }
    }
  })
}), rt = (e) => [Le, e], Cs = ({
  id: e,
  params: t,
  ...s
}) => {
  const n = !!e;
  return {
    ...C["1m"],
    ...s,
    enabled: n,
    queryKey: n ? rt(e) : E,
    queryFn: n ? () => Y.hubspot.getCompanyById(e, t) : A
  };
}, ot = [Re], ps = {
  queryKey: ot,
  queryFn: Y.getProviders,
  ...C["1h"]
}, As = (e) => {
  const t = ae(e), { onSuccess: s, onError: n } = e, { isError: r, isSuccess: o, error: i, data: a } = t;
  return l(() => {
    o && s && s(a);
  }, [a, o, s]), l(() => {
    r && n && n(i);
  }, [i, r, n]), t;
}, ls = (e) => {
  const t = X(e), { onSuccess: s, onError: n } = e, { isError: r, isSuccess: o, error: i, data: a } = t;
  return l(() => {
    o && s && s(a);
  }, [a, o, s]), l(() => {
    r && n && n(i);
  }, [i, r, n]), t;
}, it = {
  refetchOnMount: !1,
  refetchOnReconnect: !1,
  refetchOnWindowFocus: !1,
  refetchInterval: !1,
  useErrorBoundary: !1,
  enabled: !1
}, ds = (e, t) => {
  const s = J(), n = _e(
    () => ({
      queryKey: e,
      ...it,
      ...t
    }),
    [t, e]
  ), r = Se(n), [o, i] = V(
    () => new x(s, n)
  ), [a, y] = V(
    o == null ? void 0 : o.getOptimisticResult(n)
  );
  l(() => {
    r.current = n;
  }, [n]), l(() => {
    if (!(e != null && e.length)) return;
    const _ = new x(s, r.current);
    i(_);
    const p = _.getOptimisticResult(r.current);
    y(p);
  }, [s, e]), l(() => {
    const _ = o.subscribe((p) => {
      y(p);
    });
    return () => {
      _();
    };
  }, [o]);
  const c = ge(() => {
    const _ = o.getOptimisticResult(r.current);
    y(_);
  }, [o]);
  return { ...a, refresh: c };
}, Rs = ({
  options: e,
  params: t
}) => ({
  ...e,
  mutationFn: (s) => v.requestAccess(s.slug, {
    ...t,
    data: s
  })
}), Os = ({
  options: e,
  params: t
} = {}) => ({
  ...e,
  mutationFn: (s) => v.updateSlug(s.slug, s.lobbyID, {
    ...t,
    data: s
  })
}), fs = ({
  options: e,
  params: t
}) => ({
  ...e,
  mutationFn: (s) => v.startLobbySession(s.lobbyID, s.session, s.participants, {
    ...t,
    data: s
  })
}), Ks = ({
  queryClient: e,
  slug: t,
  options: s
}) => {
  const n = t ? We(t) : E, r = `lobby-enter-${t}`;
  return {
    ...R(0),
    ...s,
    queryKey: n,
    braidKey: r,
    enabled: !!t,
    queryFn: t ? () => v.enter(t, {
      ...f(n, e),
      key: r
    }) : A
  };
}, Ts = ({
  userId: e,
  options: t,
  params: s
}) => {
  const n = !!e, r = n ? $(e) : E;
  return {
    ...D["3m"],
    ...t,
    queryKey: r,
    queryFn: n ? () => H.threads(s) : A
  };
}, qs = ({
  id: e,
  options: t,
  params: s
}) => {
  const n = !!e, r = n ? $(e) : E;
  return {
    ...C["3m"],
    ...t,
    queryKey: r,
    enabled: n,
    queryFn: n ? () => H.thread(e, s) : A
  };
}, Fs = ({
  id: e,
  options: t,
  params: s,
  onFetch: n
}) => {
  const r = !!e, o = r ? q(e) : E;
  return {
    ...C["3m"],
    queryKey: o,
    enabled: r,
    ...t,
    queryFn: r ? () => (n == null || n(), H.threadMessages(e, s)) : A
  };
}, Ps = ({
  id: e,
  options: t,
  params: s
}) => ({
  ...t,
  mutationFn: (n) => H.deleteThread(e, {
    ...s,
    data: n
  })
}), at = (e) => [
  De,
  e
], bs = (e) => ({
  queryKey: e ? at(e) : E,
  queryFn: e ? () => z.getEpisodes(e) : A,
  ...C["5m"],
  enabled: !!e
}), ut = (e, t) => [
  qe,
  e,
  t
], Ds = (e, t) => {
  const s = !!e && !!t;
  return {
    queryKey: s ? ut(e, t) : E,
    ...C["15m"],
    queryFn: s ? () => z.getRecording(e, t) : A,
    enabled: s
  };
}, Ms = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: s,
  options: n,
  params: r
}) => {
  const o = !!e, i = o ? L(e, t) : E, a = `get-session-pending-access-${e}-${t}`;
  return {
    ...R(),
    ...n,
    queryKey: i,
    braidKey: a,
    enabled: o,
    queryFn: ({ signal: y }) => b.inReview({
      ...f(i, s),
      ...r,
      signal: y,
      key: a,
      params: {
        ...r == null ? void 0 : r.params,
        sessionID: e || "",
        sessionRecurrenceID: t
      }
    })
  };
}, Qs = ({
  userId: e,
  sessionId: t,
  recurrenceId: s = "",
  queryClient: n,
  options: r,
  params: o
}) => {
  const i = !!e && !!t, a = i ? Ue(e, t, s) : E, y = `get-session-request-access-${t}-${s}-${e}`;
  return {
    ...R(),
    ...r,
    queryKey: a,
    braidKey: y,
    enabled: i,
    queryFn: i ? ({ signal: c }) => {
      const _ = { ...o == null ? void 0 : o.params, sessionID: t };
      return s && (_.sessionRecurrenceID = s), b.userSessionRequest({
        ...f(a, n),
        ...o,
        signal: c,
        key: y,
        params: _
      });
    } : A
  };
}, Ns = ({
  options: e,
  params: t
} = {}) => ({
  ...e,
  mutationFn: (s) => b.requestAccess({
    ...t,
    data: s
  })
}), Ys = ({
  options: e,
  params: t
}) => ({
  ...e,
  mutationFn: (s) => b.update({
    ...t,
    data: s
  })
}), yt = (e, t) => [
  I,
  e,
  t,
  F.GRANTED
], vs = (e, t, s) => ({
  queryKey: yt(e, t),
  queryFn: () => b.getViewer({
    params: {
      limit: 50,
      ...s,
      sessionID: e,
      sessionRecurrenceID: t,
      restrictionStatus: F.GRANTED
    }
  }),
  ...D["3m"]
}), Hs = ({
  sessionId: e,
  recurrenceId: t = "",
  userId: s,
  onFetched: n,
  onPatch: r,
  onError: o,
  options: i,
  params: a,
  queryClient: y
}) => {
  const c = !!e && !!s, _ = c ? $e(e, t, s) : E, p = `get-session-${e}-${t}-${s}`;
  return {
    ...R(),
    initialData: void 0,
    retry: 2,
    enabled: c,
    ...i,
    queryKey: _,
    braidKey: p,
    queryFn: c ? ({ signal: K }) => d.getById(e, {
      ...a,
      key: p,
      signal: K,
      onFetched: (T) => {
        y.setQueryData(_, T), n && n(T);
      },
      onPatch: r,
      onError: o
    }) : void 0
  };
}, Bs = ({
  sessionId: e,
  recurrenceId: t = "",
  options: s,
  params: n
}) => ({
  ...s,
  mutationFn: (r) => d.update(e, t, {
    ...n,
    data: r
  })
}), Gs = ({
  sessionId: e
}) => ({
  mutationFn: () => d.end(e)
}), Is = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: s,
  options: n,
  params: r
}) => {
  const o = e ? we(e, t) : E, i = `get-session-presence-${e}-${t}`;
  return {
    ...R(),
    enabled: !!e,
    ...n,
    queryKey: o,
    braidKey: i,
    queryFn: e ? ({ signal: a }) => d.presence(e, {
      ...f(o, s),
      ...r,
      signal: a,
      key: i
    }) : void 0
  };
}, Ls = ({
  sessionId: e,
  params: t,
  options: s
}) => ({
  ...s,
  mutationFn: (n) => d.updateParticipantMetadata(e, {
    ...t,
    data: n
  })
}), te = 50, N = (e) => [
  U,
  de,
  e
], ks = (e, {
  limit: t = te
} = {}) => ({
  queryKey: e ? N(e) : E,
  queryFn: async ({ pageParam: s = 0 }) => d.getSessionsLibraryUpcoming({
    params: {
      limit: t,
      cursor: s * t
    }
  }),
  initialPageParam: 0,
  ...D["15s"],
  getNextPageParam: (s, n) => n.length + 1,
  enabled: !!e
}), Et = (e) => [
  U,
  le,
  e
], Us = (e, {
  limit: t = te
} = {}) => ({
  queryKey: e ? Et(e) : E,
  queryFn: async ({ pageParam: s = 0 }) => d.getSessionsLibraryHistory({
    params: {
      limit: t,
      cursor: s * t
    }
  }),
  initialPageParam: 0,
  ...D["30s"],
  getNextPageParam: (s, n) => n.length + 1,
  enabled: !!e
}), $s = ({
  userId: e,
  accessToken: t,
  params: s
}) => ({
  mutationFn: () => {
    if (!e || !t) throw new Error("Unauthorized");
    return B.activateTrial(e, {
      ...s,
      data: { token: t }
    });
  }
}), ws = ({
  accessToken: e,
  params: t
}) => ({
  mutationFn: () => ne.hostOptIn({
    data: { accessToken: e },
    ...t
  })
}), _t = () => [ve], xs = () => ({
  queryKey: _t(),
  queryFn: re.getPlans,
  ...C["1h"]
}), St = (e, t) => [
  Ne,
  e,
  t
], Vs = (e, t) => {
  const s = !!e && !!t;
  return {
    queryKey: s ? St(e, t) : E,
    queryFn: s ? () => oe.getStream(t) : void 0,
    enabled: s,
    ...D["15m"]
  };
}, Ws = ({
  id: e,
  options: t,
  params: s
}) => {
  const n = xe(e);
  return {
    ...C["3m"],
    ...t,
    queryKey: n,
    queryFn: ({ signal: r }) => B.getById(e, { ...s, signal: r })
  };
}, gt = (e, t) => [
  He,
  e,
  t
], js = (e, t, s) => ({
  queryKey: gt(e, t),
  queryFn: () => B.getTransactions(e, {
    params: { includeInvoices: "true", ...s }
  }),
  ...C["3m"]
}), ct = (e) => [Te, e], zs = (e, t) => {
  const s = !!e && !!t;
  return {
    queryKey: s ? ct(e) : E,
    queryFn: e ? () => B.getPaymentDetails(e || "") : A,
    ...C["3m"],
    enabled: s
  };
}, mt = () => [Ie], Js = () => ({
  queryKey: mt(),
  queryFn: ie.get,
  ...C["1h"]
}), Ct = {
  add: ({ client: e, data: t, userId: s }) => {
    t && m.request.addToPages({
      client: e,
      key: N(s),
      updateFn: (n) => {
        const r = (n == null ? void 0 : n.sessions) || [];
        return {
          ...n,
          count: ((n == null ? void 0 : n.count) || r.length) + 1,
          sessions: [t, ...r]
        };
      }
    });
  },
  update: ({ client: e, data: t, userId: s }) => {
    t && m.request.updateInPages({
      client: e,
      key: N(s),
      stopOnChange: !0,
      updateFn: (n) => {
        if (!(n != null && n.sessions)) return n;
        const r = n.sessions.findIndex((i) => i.sessionID === t.sessionID);
        if (r === -1) return n;
        const o = {
          ...n,
          sessions: [...n.sessions]
        };
        return o.sessions[r] = t, o;
      }
    });
  },
  remove: ({ client: e, userId: t, id: s }) => {
    let n;
    return m.request.updateInPages({
      client: e,
      key: N(t),
      stopOnChange: !0,
      updateFn: (r) => {
        if (!(r != null && r.sessions)) return r;
        const o = r.sessions.findIndex((a) => a.sessionID === s);
        if (o === -1) return r;
        const i = { ...r, sessions: [...r.sessions] };
        return i.count = Math.max(0, (i.count || 1) - 1), n = i.sessions.splice(o, 1)[0], i;
      }
    }), n;
  }
}, pt = {
  add: ({ client: e, data: t = [] }) => {
    if (!t.length) return;
    const { sessionID: s, sessionRecurrenceID: n } = t[0];
    m.request.update({
      client: e,
      key: [
        I,
        s,
        n,
        F.GRANTED
      ],
      dataOrFn: (r = {}) => {
        var p, K;
        const o = { ...r }, i = (T, G) => (G.type === "domain" ? T[0].push(G) : T[1].push(G), T), [a, y] = ((K = (p = o == null ? void 0 : o.data) == null ? void 0 : p.viewerAccessRules) == null ? void 0 : K.reduce(
          i,
          [[], []]
        )) || [], [c, _] = t.reduce(i, [
          [],
          []
        ]);
        return {
          data: {
            viewerAccessRules: [
              ...c,
              ...a || [],
              ..._,
              ...y || []
            ]
          }
        };
      }
    });
  },
  remove: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: s,
    id: n
  }) => {
    m.request.update({
      client: e,
      key: [
        I,
        t,
        s,
        F.GRANTED
      ],
      dataOrFn: (r) => {
        var i;
        const o = ((i = r == null ? void 0 : r.data.viewerAccessRules) == null ? void 0 : i.filter((a) => a.id !== n)) || [];
        return {
          ...r,
          data: {
            ...r == null ? void 0 : r.data,
            viewerAccessRules: o
          }
        };
      }
    });
  },
  // requests
  addRequest: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: s,
    data: n
  }) => {
    var a;
    const r = L(t, s), o = e.getQueryData(r) || W(void 0), i = {
      ...o,
      data: {
        ...o.data,
        accessRules: [...((a = o.data) == null ? void 0 : a.accessRules) || [], ...n]
      }
    };
    e.setQueryData(r, i);
  },
  removeRequest: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: s,
    id: n
  }) => {
    var _, p;
    const r = L(t, s), o = e.getQueryData(r);
    if (!((_ = o == null ? void 0 : o.data) != null && _.accessRules)) return;
    const i = o.data.accessRules, a = i == null ? void 0 : i.findIndex((K) => K.id === n);
    if (a === -1) return;
    const y = {
      ...o,
      data: {
        ...o.data,
        accessRules: [...((p = o.data) == null ? void 0 : p.accessRules) || []]
      }
    }, c = y.data.accessRules.splice(a, 1)[0];
    return e.setQueryData(r, y), c;
  }
}, Xs = {
  session: Ct,
  viewerAccess: pt
};
export {
  vt as CACHE_KEY_BRANCH,
  h as CACHE_KEY_CALENDAR_EVENT,
  pe as CACHE_KEY_CMS_PAGE,
  Ae as CACHE_KEY_CMS_PAGES,
  Kt as CACHE_KEY_CURRENT_USER,
  le as CACHE_KEY_DASHBOARD_PAST_SESSIONS,
  de as CACHE_KEY_DASHBOARD_SESSIONS,
  Tt as CACHE_KEY_FEATURED_SESSIONS,
  wt as CACHE_KEY_HUBSPOT_GET_BINDING_BY_ID,
  Le as CACHE_KEY_HUBSPOT_GET_COMPANY_BY_ID,
  Wt as CACHE_KEY_HUBSPOT_GET_CONTACTS,
  jt as CACHE_KEY_HUBSPOT_GET_CONTACT_BY_ID,
  xt as CACHE_KEY_HUBSPOT_GET_DEAL_BY_ID,
  Vt as CACHE_KEY_HUBSPOT_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  $t as CACHE_KEY_HUBSPOT_PROVIDER,
  qt as CACHE_KEY_INTEGRATIONS_CONNECTIONS,
  Ft as CACHE_KEY_INTEGRATIONS_DIRECT_URL,
  Pt as CACHE_KEY_INTEGRATIONS_GET_ACTION,
  Ge as CACHE_KEY_INTEGRATIONS_GET_BY_OWNER_SESSION_RECURRENCE,
  Re as CACHE_KEY_INTEGRATIONS_PROVIDERS,
  Ye as CACHE_KEY_LISTENING_MODE_SPEAKERS,
  ke as CACHE_KEY_LOBBY_ENTER,
  Oe as CACHE_KEY_MEETING_MEMORY_MESSAGES,
  bt as CACHE_KEY_MEETING_MEMORY_SUGGESTIONS,
  fe as CACHE_KEY_MEETING_MEMORY_THREAD,
  Ke as CACHE_KEY_MEETING_MEMORY_THREADS,
  Te as CACHE_KEY_PAYMENT_DETAILS,
  U as CACHE_KEY_PRIVATE_BASE,
  qe as CACHE_KEY_RECORDING,
  Dt as CACHE_KEY_RECORDING_STATUS,
  Gt as CACHE_KEY_SALESFORCE_GET_ACCOUNT_BY_ID,
  Bt as CACHE_KEY_SALESFORCE_GET_BINDING_BY_ID,
  kt as CACHE_KEY_SALESFORCE_GET_CONTACTS,
  Ut as CACHE_KEY_SALESFORCE_GET_LEAD_BY_ID,
  It as CACHE_KEY_SALESFORCE_GET_OPPORTUNITY_BY_ID,
  Lt as CACHE_KEY_SALESFORCE_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  Ht as CACHE_KEY_SALESFORCE_PROVIDER,
  Mt as CACHE_KEY_SESSION,
  Qt as CACHE_KEY_SESSION_ACCESS_RULE,
  I as CACHE_KEY_SESSION_ACCESS_RULES,
  Pe as CACHE_KEY_SESSION_AI_FEED,
  be as CACHE_KEY_SESSION_END_SUMMARY,
  De as CACHE_KEY_SESSION_EPISODES,
  Qe as CACHE_KEY_SESSION_GET_CARDS,
  Nt as CACHE_KEY_SESSION_PENDING_RULES_STATS,
  Me as CACHE_KEY_SESSION_PRESENCE,
  ee as CACHE_KEY_SESSION_REQUEST_ACCESS,
  Fe as CACHE_KEY_SESSION_V2,
  Yt as CACHE_KEY_SESSION_VIEWERS,
  Ne as CACHE_KEY_STREAM_TOKEN,
  ve as CACHE_KEY_SUBSCRIPTION_PLANS,
  He as CACHE_KEY_SUBSCRIPTION_RECEIPTS,
  Be as CACHE_KEY_USER,
  Ie as CACHE_KEY_USER_MEETING_TYPES,
  $s as activateTrialMutation,
  D as activeOptions,
  Zt as aiFeedCacheService,
  as as aiFeedEventMutation,
  R as baseBraidOptions,
  zt as buildPrivateQueryKey,
  m as cacheService,
  Es as calendarEventQuery,
  Ss as cmsPageQuery,
  gs as cmsPagesQuery,
  ms as createCRMBindingMutation,
  Ze as defaultBraidOnFetched,
  he as defaultBraidOnPatch,
  os as disabledFetchOptions,
  E as emptyCacheKey,
  A as emptyFn,
  Gs as endSessionMutation,
  S as getActiveOptions,
  Q as getAiFeedQueryKey,
  cs as getCRMProviderBindingsForSessionQuery,
  nt as getCRMProviderBindingsForSessionQueryKey,
  ys as getCalendarEventQueryKey,
  _s as getCardsQuery,
  et as getCardsQueryKey,
  tt as getCmsPageQueryKey,
  st as getCmsPagesQueryKey,
  f as getDefaultBraidCallbacks,
  g as getInactiveOptions,
  ss as getInfinityRequestData,
  ot as getIntegrationsProvidersQueryKey,
  Xt as getListeningModeSpeakersQueryKey,
  We as getLobbyEnterQueryKey,
  q as getMeetingMemoryThreadMessagesQueryKey,
  Jt as getMeetingMemoryThreadQueryKey,
  $ as getMeetingMemoryThreadsQueryKey,
  Et as getPastSessionQueryKey,
  ct as getPaymentDetailsQueryKey,
  xs as getPlansQuery,
  _t as getPlansQueryKey,
  ts as getQueryRequestData,
  at as getRecordingEpisodesQueryKey,
  ut as getRecordingQueryKey,
  Je as getRequestData,
  is as getSessionAiFeedQuery,
  Ve as getSessionEndSummaryQueryKey,
  yt as getSessionGrantedAccessRulesQueryKey,
  we as getSessionPresenceKey,
  Hs as getSessionQuery,
  $e as getSessionQueryKey,
  St as getStreamTokenQueryKey,
  N as getUpcomingSessionQueryKey,
  mt as getUserMeetingTypesQueryKey,
  xe as getUserQueryKey,
  gt as getUserTransactionsQueryKey,
  ws as hostOptInMutation,
  rt as hubspotGetCompanyQueryKey,
  Cs as hubstopGetCompanyQuery,
  C as inactiveOptions,
  es as initConfig,
  ps as integrationsProvidersQuery,
  Xe as jsonPatch,
  ns as jsonPatchArrayFallbackValidator,
  Ks as lobbyEnterQuery,
  O as logger,
  ht as meetingMemoryCacheService,
  Ps as meetingMemoryDeleteThreadMutation,
  Fs as meetingMemoryThreadMessagesQuery,
  qs as meetingMemoryThreadQuery,
  Ts as meetingMemoryThreadsQuery,
  u as milliseconds,
  Us as pastSessionQuery,
  zs as paymentDetailsQuery,
  it as queryDataDefaultOptions,
  bs as recordingEpisodesQuery,
  Ds as recordingQuery,
  Ns as requestAccessMutation,
  Rs as requestLobbyAccessMutation,
  Xs as sessionCacheService,
  us as sessionEndSummaryQuery,
  vs as sessionGrantedAccessRulesQuery,
  Ms as sessionPendingAccessQuery,
  L as sessionPendingAccessQueryKey,
  Is as sessionPresenceQuery,
  fs as startLobbySessionMutation,
  Vs as streamTokenQuery,
  ks as upcomingSessionQuery,
  Ls as updateParticipantMetadataMutation,
  Ys as updateSessionAccessMutation,
  Bs as updateSessionMutation,
  Os as updateSlugMutation,
  rs as useBraid,
  As as useInfiniteQuery,
  ls as useQuery,
  ds as useQueryData,
  Js as userMeetingTypesQuery,
  Ws as userQuery,
  Qs as userSessionRequestAccessQuery,
  Ue as userSessionRequestAccessQueryKey,
  js as userTransactionsQuery
};
