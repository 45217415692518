import { braidService as y } from "@waitroom/braid";
import { braidService as re } from "@waitroom/braid";
import { bearerValue as B, httpService as a } from "@waitroom/http-client";
import { httpService as ue } from "@waitroom/http-client";
let c;
const R = (e) => {
  c = {
    ...c,
    ...e
  };
}, F = (e) => {
  R(e);
}, f = async (e) => typeof e == "function" ? await e() : await e, C = async () => {
  const e = await f(c.getAuthToken);
  return {
    Authorization: e ? B(e) : void 0
  };
}, k = async () => {
  const [e, t] = await Promise.allSettled([
    f(c.getFingerPrint),
    f(c.getClientDetails)
  ]);
  return {
    "X-Fingerprint": e.status === "fulfilled" ? e.value : "",
    "X-Client-Info": JSON.stringify(t.status === "fulfilled" ? t.value : {}),
    "Accept-Language": "en"
    // TODO: This should be updated to pick the app's language
  };
}, T = (e, t) => `${e || c.baseURL}/${t || c.version}`, O = async (e, t) => ({
  ...await k(),
  // add auth header if non provided
  ...e && !(t != null && t.Authorization) ? await C() : {},
  ...t
}), P = (e) => async (t, { version: i, baseURL: n, headers: l, ...$ } = {}) => ({
  url: t,
  baseURL: T(n, i),
  headers: await O(e, l),
  ...$
}), o = P(!1), s = P(!0), D = {
  getFeed: async (e, t, i) => y.fetch(
    await s("/ai-feed/get", {
      ...c.defaultBraidParams,
      ...i,
      params: {
        ...i == null ? void 0 : i.params,
        sessionID: e,
        sessionRecurrenceID: t
      }
    })
  ),
  event: async (e, t, i) => a.post(
    await s("/ai-feed/event", {
      ...c.defaultBraidParams,
      ...i,
      data: {
        ...i == null ? void 0 : i.data,
        sessionID: e,
        sessionRecurrenceID: t
      }
    })
  ),
  updateFeed: async (e, t) => a.patch(await s(`/ai-feed/${e}`, t)),
  getSummary: async (e, t, i) => a.get(
    await s(`/post-session-summaries/by-session-ids/${e}`, {
      ...i,
      params: {
        formats: "md",
        ...i == null ? void 0 : i.params,
        sessionRecurrenceID: t
      }
    })
  ),
  internalCopyAIFeed: async (e) => a.post(await s("/ai-feed/copy", e))
}, E = "/auth", z = {
  verifyOTP: async (e) => a.post(await o("/auth/verify-otp", e)),
  verifyOAuthOTP: async (e) => a.post(await o("/oauth/verify-otp", e)),
  requestOTP: async (e) => a.post(
    await o("/auth/request-otp", {
      ...e,
      data: {
        ...e.data,
        fingerprint: await c.getFingerPrint
      }
    })
  ),
  requestOAuthOTP: async (e) => a.post(
    await o("/oauth/request-otp", {
      ...e,
      data: {
        ...e.data,
        fingerprint: await c.getFingerPrint
      }
    })
  ),
  verifyAuthTokenProvider: async (e) => a.post(await o("/auth/social-token", e)),
  verifyOAuthTokenProvider: async (e) => a.post(await o("/oauth/social-token", e)),
  verifyAuthCodeProvider: async (e) => a.post(await o("/auth/social-code", e)),
  verifyOAuthCodeProvider: async (e) => a.post(await o("/oauth/social-code", e)),
  refreshToken: async (e) => a.put(await o("/auth/refresh-auth-token", e)),
  logout: async (e) => a.post(await s("/auth/logout", e)),
  hostOptIn: async (e) => a.patch(await s("/auth/user/host-opt-in", e)),
  user: async (e) => a.get(await s("/auth/user", e)),
  registerGuest: async (e) => a.post(await s("/auth/guest", e))
}, M = {
  getCalndrLinkEvent: async (e) => a.post({
    url: "https://calndr.link/api/events",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...e.headers
    },
    ...e
  })
}, I = `
  _id,
  type,
  hero,
  cta,
  vertical,
  horizontal,
  grid,
  testimonials,
  blockList,
  features,
  pricing,
  faq,
  compareTable,
  investors
`, m = `
  _id,
  title,
  description,
  slug,
  url,
  image,
  category,
  type,
  menuTitle,
  menuIcon,
  menuOrder,
  hideHeaderMenu
`, q = `
  ${m},
  sections
`, V = {
  getPage: async (e, t) => {
    var n, l;
    const i = await a.get({
      ...t,
      url: c.sanityUrl,
      params: {
        ...t == null ? void 0 : t.params,
        query: `*[_type == "page" && slug.current == "${e}"]{
          ${q},
          sections[] {
            ...,
            section->{
              ${I}
            }
          }
        }`
      },
      throwError: !1,
      withCredentials: !0
    });
    if (((l = (n = i.data) == null ? void 0 : n.result) == null ? void 0 : l.length) === 0) throw Error("Nothing found");
    return i;
  },
  getPages: async (e, t) => a.get({
    ...t,
    url: c.sanityUrl,
    params: {
      ...t == null ? void 0 : t.params,
      query: `*[_type == "page"${e != null && e.length ? ` && (${e.map((i) => `type == "${i}"`).join(" || ")})` : ""}]{${m}}|order(category desc, menuOrder asc)`
    },
    throwError: !1,
    withCredentials: !0
  })
}, _ = {
  getPresignedUrl: async (e) => a.get(await s("/image-uploads/presigned-url", e))
}, d = "/integrations", u = `${d}/salesforce`, w = `${d}/hubspot`, j = {
  getProviders: async (e) => a.get(await o(`${d}/providers`, e)),
  getConnections: async (e) => a.get(await s(`${d}/connections`, e)),
  getAction: async (e, t, i) => a.get(await s(`${d}/${e}/${t}`, i)),
  callAction: async (e, t, i) => a.post(await s(`${d}/${e}/${t}`, i)),
  getFromAbsoluteURL: async (e, t) => a.get(await s("", { url: e, ...t })),
  postToAbsoluteURL: async (e, t) => a.post(await s("", { url: e, ...t })),
  absoluteURL: async (e, t, i) => a.request(await s("", { url: t, method: e, ...i })),
  authorize: async (e) => a.get(await s(`${d}/authorize`, e)),
  disconnect: async (e) => a.delete(await s(`${d}/disconnect`, e)),
  getBindingsByOwnerSessionRecurrencePerProvider: async (e, t, i) => y.fetch(
    await s(
      `${d}/-/get-bindings-by-owner-session-recurrence-per-provider`,
      {
        ...i,
        ...c.defaultBraidParams,
        params: {
          sessionId: e,
          sessionRecurrenceId: t
        }
      }
    )
  ),
  // Salesforce
  salesforce: {
    createBinding: async (e) => a.post(
      await s(`${u}/bindings`, e)
    ),
    deleteBinding: async (e, t) => a.delete(
      await s(`${u}/bindings/${e}`, t)
    ),
    getAccountById: async (e, t) => a.get(
      await s(`${u}/accounts/${e}`, t)
    ),
    getOpportunityById: async (e, t) => a.get(
      await s(`${u}/opportunities/${e}`, t)
    ),
    listBindableRecordsAsOptions: async (e) => a.get(
      await s(
        `${u}/-/list-bindable-records-as-options`,
        e
      )
    ),
    createContact: async (e, t) => a.post(
      await s(`${u}/contacts`, {
        data: e,
        ...t
      })
    ),
    getContacts: async (e, t, i) => a.get(
      await s(`${u}/contacts/-/list-related-to-record`, {
        params: {
          ...i == null ? void 0 : i.params,
          recordId: e,
          recordType: t
        },
        ...i
      })
    ),
    getLeadById: async (e, t) => a.get(
      await s(`${u}/leads/${e}`, t)
    )
  },
  // Hubspot
  hubspot: {
    createBinding: async (e) => a.post(
      await s(`${w}/bindings`, e)
    ),
    deleteBinding: async (e, t) => a.delete(
      await s(`${w}/bindings/${e}`, t)
    ),
    getCompanyById: async (e, t) => a.get(
      await s(`${w}/companies/${e}`, t)
    ),
    getDealById: async (e, t) => a.get(
      await s(`${w}/deals/${e}`, t)
    ),
    listBindableRecordsAsOptions: async (e) => a.get(
      await s(
        `${w}/-/list-bindable-records-as-options`,
        e
      )
    ),
    getContacts: async (e, t, i) => a.get(
      await s(`${w}/contacts/-/list-related-to-record`, {
        params: {
          ...i == null ? void 0 : i.params,
          recordId: e,
          recordType: t
        },
        ...i
      })
    ),
    getContactById: async (e, t) => a.get(
      await s(`${w}/contacts/${e}`, t)
    )
  }
}, v = "/lobbies", x = {
  enter: async (e, t) => y.fetch(
    await s(`${v}/enter`, {
      ...c.defaultBraidParams,
      ...t,
      params: {
        ...t == null ? void 0 : t.params,
        lobbyLink: e
      }
    })
  ),
  startLobbySession: async (e, t, i, n) => a.post(
    await s(`${v}/start-session`, {
      ...n,
      data: {
        ...n == null ? void 0 : n.data,
        session: t,
        participants: i,
        lobbyID: e
      }
    })
  ),
  updateSlug: async (e, t, i) => a.put(
    await s(`${v}/slug`, {
      ...i,
      data: {
        ...i == null ? void 0 : i.data,
        slug: e,
        lobbyID: t
      }
    })
  ),
  requestAccess: async (e, t) => a.post(
    await s(`${v}/request-access`, {
      ...t,
      data: {
        ...t == null ? void 0 : t.data,
        slug: e
      }
    })
  )
}, g = "/memory", G = {
  ai: async (e, t, i) => {
    const n = await s(`${g}/ai-stream`, t);
    return c.fetchEventSource(`${n.baseURL}${n.url}`, {
      method: "POST",
      body: JSON.stringify(n.data),
      headers: n.headers,
      ...i,
      onmessage: e
    });
  },
  thread: async (e, t) => a.get(await s(`${g}/threads/${e}`, t)),
  threadMessages: async (e, t) => a.get(await s(`${g}/threads/${e}/messages`, t)),
  threads: async (e) => a.get(await s(`${g}/threads`, e)),
  deleteThread: async (e, t) => a.delete(await s(`${g}/threads/${e}`, t)),
  stop: async (e) => a.post(await s(`${g}/stop`, e)),
  feedback: async (e) => a.patch(await s(`${g}/feedback`, e)),
  suggestions: async (e) => a.get(await s(`${g}/suggestions`, e))
}, H = {
  // Used to let a user connect their google calendar to recall
  getGoogleAuthURL: async () => {
    var t;
    return ((t = (await a.get(
      await s("/transcriptions/bot/google-auth")
    )).data) == null ? void 0 : t.authURL) ?? "";
  },
  // ATTN: Only google is supported for now:
  disconnectCalendar: async (e) => {
    await a.post(
      await s("/transcriptions/bot/disconnect", {
        data: {
          platform: e
        }
      })
    );
  },
  getCalendarUser: async () => (await a.get(
    await s("/transcriptions/bot/calendar-user")
  )).data ?? null
}, N = {
  getStatus: async (e, t, i) => a.get(
    await s(`/recordings/${e}/recurrence/${t}/status`, {
      ...i,
      version: "v2.0"
    })
  ),
  getRecording: async (e, t, i) => a.get(
    await s(
      `/recordings/${e}/recurrence/${t}/recording`,
      { ...i, version: "v2.0" }
    )
  ),
  getPeople: async (e, t, i) => a.get(
    await s(`/recordings/${e}/recurrence/${t}/people`, {
      ...i,
      version: "v2.0"
    })
  ),
  getEpisodes: async (e, t) => a.get(
    await s(`/recordings/${e}/episodes`, {
      ...t,
      version: "v2.0"
    })
  ),
  getSessionRecordings: async (e, t, i) => a.get(
    await s(`/recordings/by-session/${e}/${t}`, i)
  ),
  getLatestPlayable: async (e) => a.get(await s("/recordings/latest-playable", e))
}, J = {
  getById: async (e, t) => y.fetch(
    await s(`/sessions/get/${e}`, {
      ...c.defaultBraidParams,
      ...t,
      version: "v2.0"
    })
  ),
  getFeatured: async (e) => a.get(await o("/sessions/featured", e)),
  subscribe: async (e) => a.post(await s("/sessions/subscribe", e)),
  unsubscribe: async (e) => a.post(await s("/sessions/unsubscribe", e)),
  getCategories: async (e) => a.get(await s("/sessions/category", e)),
  create: async (e) => a.post(await s("/sessions", e)),
  update: async (e, t, i) => a.patch(
    await s(`/sessions/${e}/recurrence/${t}`, i)
  ),
  end: async (e) => a.post(await s(`/meeting/${e}/end`)),
  delete: async (e, t, i) => a.patch(
    await s(`sessions/${e}/recurrence/${t}`, i)
  ),
  getPast: async (e) => a.get(await s("/sessions/get-past-sessions", e)),
  getFuture: async (e) => a.get(await s("/sessions/get-future-sessions", e)),
  restartSession: async (e, t, i) => a.patch(
    await s(`/sessions/${e}/recurrence/${t}/recur`, i)
  ),
  getSessionsLibraryHistory: async (e) => a.get(await s("/sessions/library/history", e)),
  getSessionsLibraryUpcoming: async (e) => a.get(await s("/sessions/library/upcoming", e)),
  presence: async (e, t) => y.fetch(
    await s(`/sessions/presence/${e}`, {
      ...c.defaultBraidParams,
      ...t
    })
  ),
  updateParticipantMetadata: async (e, t) => a.patch(await s(`meeting/${e}/participant`, t)),
  registerGuest: async (e) => a.post(await s("/sessions/guest", e)),
  cards: async (e, t, i) => y.fetch(
    await s("/sessions/cards", {
      params: {
        sessionId: e,
        sessionRecurrenceId: t
      },
      ...c.defaultBraidParams,
      ...i
    })
  )
}, r = "/sessions/access", X = {
  inReview: async (e) => y.fetch(
    await s(`${r}/in-review`, {
      ...c.defaultBraidParams,
      ...e
    })
  ),
  userSessionRequest: async (e) => y.fetch(
    await s(`${r}/get`, {
      ...c.defaultBraidParams,
      ...e
    })
  ),
  getViewer: async (e) => a.get(await s(`${r}/viewer`, e)),
  getViewerAccessStats: async (e) => a.get(await s(`${r}/get-viewer-access-stats`, e)),
  getInReviewViewerAccessStats: async (e) => a.post(
    await s(`${r}/get-in-review-access-requests-count`, e)
  ),
  requestAccess: async (e) => a.post(await s(`${r}/request-access`, e)),
  update: async (e) => a.put(await s(`${r}/request`, e)),
  add: async (e) => a.put(await s(`${r}/add`, e)),
  remove: async (e) => a.delete(await s(`${r}/remove`, e))
}, A = "/session-recordings/off-the-record", W = {
  enableOffTheRecord: async (e) => a.post(
    await s(`${A}/enable`, {
      version: "v2.0",
      ...e
    })
  ),
  disableOffTheRecord: async (e) => a.post(
    await s(`${A}/disable`, {
      version: "v2.0",
      ...e
    })
  )
}, K = {
  getPlans: async (e) => a.get(await o("/billing/plans", { ...e })),
  getPlan: async (e, t) => a.get(
    await o(`/billing/plans/${e}`, {
      ...t
    })
  )
}, Q = {
  getByUserId: async (e, t, i, n = "audience") => a.post(
    await s("/agora/token", {
      data: {
        userId: t,
        sessionId: e,
        role: n,
        expirationTs: i,
        tokenType: "userAccount"
      }
    })
  ),
  getByUid: async (e, t, i, n = "audience") => a.post(
    await s("/agora/token", {
      data: {
        sessionId: e,
        uid: t,
        role: n,
        expirationTs: i,
        tokenType: "uid"
      }
    })
  ),
  getChat: async (e, t, i) => a.get(
    await s("/chat/token", {
      ...i,
      params: {
        ...i == null ? void 0 : i.params,
        sessionID: e,
        sessionRecurrenceID: t
      }
    })
  ),
  getStream: async (e, t) => a.post(await s(`/meeting/${e}/token`, t))
}, S = "/transcriptions/", Y = {
  getHeardSpeakers: async (e) => a.get(await s(`${S}speakers`, e)),
  identifyHeardSpeakers: async (e) => a.post(await s(`${S}speakers`, e))
}, Z = {
  getById: async (e, t) => a.get(await s(`/users/id/${e}`, t)),
  getCurrent: async (e) => a.get(await s("/users/me", e)),
  update: async (e, { data: t, ...i }) => a.put(
    await s(`/users/id/${e}`, {
      ...i,
      data: {
        userData: t
      }
    })
  ),
  getSubscriptionPlan: async (e, t) => a.get(
    await s(`/users/${e}/plan`, {
      ...t
    })
  ),
  updateSubscriptionPlan: async (e, t) => a.patch(
    await s(`/users/${e}/plan`, {
      ...t
    })
  ),
  reactivateSubscriptionPlan: async (e, t) => a.post(
    await s(`/users/${e}/plan/reactivate`, {
      ...t
    })
  ),
  cancelSubscriptionPlan: async (e, t) => a.post(
    await s(`/users/${e}/plan/cancel`, {
      ...t
    })
  ),
  updatePaymentTransaction: async (e, t) => a.get(
    await s(`/users/${e}/update-payment-method-transaction`, {
      ...t
    })
  ),
  activateTrial: async (e, t) => a.post(
    await s(`/users/${e}/activate-trial`, {
      ...t
    })
  ),
  createCustomer: async (e, t) => a.post(
    await s(`/users/${e}/customer`, {
      ...t
    })
  ),
  getTransactions: async (e, t) => a.get(
    await s(`/users/${e}/transactions`, {
      ...t
    })
  ),
  getTransactionInvoice: async (e, t, i) => a.get(
    await s(`/users/${t}/transactions/${e}/invoice`, { ...i })
  ),
  getPaymentDetails: async (e, t) => a.get(
    await s(`/users/${e}/payment-method-details`, {
      ...t
    })
  ),
  requestDelete: async () => a.delete(await s("/users/me"))
}, ee = {
  get: async (e) => a.get(await s("/meeting-types", e))
}, te = (e) => e == null ? void 0 : e.data, ae = (e) => {
  var t;
  return (t = e == null ? void 0 : e.data) == null ? void 0 : t.data;
}, se = (e) => {
  var l, $;
  if (!e) return;
  let t, i, n = !1;
  if ("headers" in e && "error" in e) {
    const { errCode: h, statusCode: p, message: b } = e.error || {};
    t = h || p || e.code || ((l = e.data) == null ? void 0 : l.code), i = (($ = e.data) == null ? void 0 : $.message) || e.message || b;
  } else {
    let {
      code: h,
      message: p,
      success: b
    } = "data" in e ? (e == null ? void 0 : e.data) || {} : e || {};
    "message" in e && p === void 0 && ({ code: h, message: p, success: b } = e), t = h, i = p, n = !!b;
  }
  return {
    code: t,
    message: i,
    success: n
  };
}, ie = (e) => ({
  success: !0,
  code: 200,
  data: e
}), ne = (e) => ({
  success: !0,
  code: 200,
  data: {
    success: !0,
    code: 200,
    data: e
  }
});
export {
  D as aiApiService,
  z as authApiService,
  E as authBaseEndpoint,
  re as braidService,
  o as buildApiParams,
  s as buildApiParamsWithAuth,
  O as buildHeaders,
  ne as buildSuccessApiRequestResponse,
  ie as buildSuccessRequestResponse,
  M as calendarApiService,
  V as cmsApiService,
  c as config,
  te as getApiData,
  ae as getApiResponseData,
  C as getAuthHeader,
  T as getBaseURL,
  se as getResponse,
  ue as httpService,
  _ as imageApiService,
  F as initCommonApi,
  j as integrationsApiService,
  x as lobbyApiService,
  G as meetingMemoryApiService,
  H as recallCalendarService,
  N as recordingApiService,
  X as sessionAccessApiService,
  J as sessionApiService,
  W as sessionRecordingsApiService,
  K as subscriptionPlanApiService,
  Q as tokenApiService,
  Y as transcriptionsApiService,
  R as updateConfig,
  Z as userApiService,
  ee as userMeetingTypesService
};
