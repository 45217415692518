import { CheckoutEventsData, CheckoutEventsItem } from '@paddle/paddle-js';
import { CheckoutEvent, CheckoutEventType } from '@waitroom/analytics';

export class CheckoutEventConverter {
  private static reverseMapping: { [key: string]: CheckoutEventType } = {};

  static initializeReverseMapping(): void {
    for (const key in CheckoutEventType) {
      if (Object.prototype.hasOwnProperty.call(CheckoutEventType, key)) {
        const enumValue = CheckoutEventType[key as keyof typeof CheckoutEventType];
        CheckoutEventConverter.reverseMapping[enumValue] = key as CheckoutEventType;
      }
    }
  }

  static convertPaddleCheckoutEventName(eventName: string): CheckoutEventType | undefined {
    const upperCaseEventName = eventName.toUpperCase().split('.').join('_');
    return CheckoutEventConverter.reverseMapping[upperCaseEventName];
  }

  static convertPaddleEventToAnalyticsPayload(paddleEvent: CheckoutEventsData): CheckoutEvent {
    const { customer, items, currency_code, recurring_totals } = paddleEvent;

    const analyticsPayload: CheckoutEvent = {
      currency_code: currency_code,
      totals: recurring_totals,
      customer: {
        email: customer.email,
        ...(customer.address && { address: customer.address }),
      },
      payment: {
        method: paddleEvent.payment.method_details.type,
      },
      products: items.map((item: CheckoutEventsItem) => ({
        id: item.product.id,
        name: item.product.name,
        description: item.product.description,
        image_url: item.product.image_url,
      })),
    };
    return analyticsPayload;
  }
}

CheckoutEventConverter.initializeReverseMapping();
