import { initialState as generalSettings } from '@modules/core/hooks/useGeneralSettings';
import { fetchEventSource } from '@modules/core/services/fetch/fetchEventSource';
import store from '@modules/core/services/redux/configureStore';
import { init as initSentry } from '@modules/core/services/sentry';
import { storageService } from '@modules/core/services/storage';
import { getFingerPrint } from '@modules/core/utils/finger-print';
import { initConfig as initAnalytics } from '@waitroom/analytics';
import {
  authService,
  AuthState,
  axiosAuthInterceptor,
  braidOnErrorInterceptor,
  braidOnRetryInterceptor,
  getAccessToken,
  initConfig as initAuthConfig,
  updateAuthState,
} from '@waitroom/auth';
import { initConfig as initBraidConfig } from '@waitroom/braid';
import { initConfig as initChatConfig } from '@waitroom/chat';
import { featureFlagsService, initConfig as initCommon } from '@waitroom/common';
import { braidService, httpService, initCommonApi } from '@waitroom/common-api';
import { logger } from '@waitroom/logger';
import { initConfig as initRQConfig } from '@waitroom/react-query';
import { initConfig as initStreamConfig } from '@waitroom/stream';
import { BASE_URL, commonConfig, environments, featureFlags, LUXOR_URL, WORMHOLE_URL } from '.';
import { BRANCH_DOMAIN, BRANCH_KEY, BRANCH_STORE_SUFFIX } from '../../analytics/config';
import { init as initPosthog } from '../services/posthog';
import { getClientDetails } from './clientDetails';
import { CURRENT_TIMEZONE, DEFAULT_TIMEZONE } from './dateTime';

const getFP = getFingerPrint();

// enable all feature flags on dev
featureFlagsService.override = environments.isDev ? true : undefined;

// setup logger
const logEnabled = !!generalSettings.debug?.logEnabled;
logger.config.enabled = !environments.isProd || !!logEnabled;
logger.config.logLevel = generalSettings.debug?.logLevel ?? 1;

// setup other packages loader
const serviceConfig = { logger };
initAuthConfig(serviceConfig);
initBraidConfig(serviceConfig);
initChatConfig(serviceConfig);
initStreamConfig(serviceConfig);
initRQConfig(serviceConfig);

// setup error and logging services
initSentry();
initPosthog({
  enable_recording_console_log: logEnabled || environments.isStaging,
});

const onAuthUpdated = (state: AuthState) => {
  // update auth state
  store.dispatch(updateAuthState(state));
};
// setup auth interceptor for token refresh
httpService.client.interceptors.response.use(
  (r) => r,
  axiosAuthInterceptor({
    onAuthUpdated,
  }),
);
// setup braid interceptor for token refresh
braidService.config.interceptors.onError = braidOnErrorInterceptor({
  onAuthUpdated,
});
braidService.config.interceptors.onRetry = braidOnRetryInterceptor({
  onAuthUpdated,
});

// setup auth service
authService.init({ storage: storageService });

// init api config
initCommonApi({
  baseURL: WORMHOLE_URL,
  version: 'v1.0',
  getAuthToken: async () =>
    (await getAccessToken(undefined, (state) => {
      // update auth state
      store.dispatch(updateAuthState(state));
    })) || '',
  getFingerPrint: getFP,
  getClientDetails,
  fetchEventSource,
  sanityUrl: commonConfig.sanity.apiUrl,
});

// init common config
initCommon({
  domains: {
    luxor: LUXOR_URL,
    wormhole: WORMHOLE_URL,
  },
  featureFlags: featureFlags,
  storage: storageService,
  dateTime: {
    timezone: {
      default: DEFAULT_TIMEZONE,
      current: CURRENT_TIMEZONE,
    },
  },
  logger: logger,
});

initAnalytics({
  getFingerPrint: getFingerPrint(),
  getClientDetails: getClientDetails(),
  branch: {
    domain: `${BRANCH_DOMAIN}${environments.isProd ? `/a/${BRANCH_KEY}` : ''}`,
    key: BRANCH_KEY || '',
    storeSuffix: BRANCH_STORE_SUFFIX || '',
  },
  baseUrl: BASE_URL,
  storage: storageService,
});
