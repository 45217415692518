import type { Storage } from '@waitroom/models';
import { isNotEmpty, isObject, tryCatch } from '@waitroom/utils';

export const storage = tryCatch<globalThis.Storage, undefined>(() => localStorage)(() => undefined);

export const storageService: Storage = {
  get: (key, defaultValue) => {
    if (!storage) return defaultValue;
    const value = storage.getItem(key);
    return isNotEmpty(value) ? (value as string) : defaultValue;
  },
  getParsed: (key, defaultValue?, parser = JSON.parse) => {
    if (!storage) return defaultValue;
    const value = storage.getItem(key);
    if (!isNotEmpty(value)) return defaultValue;
    try {
      return parser(value as string);
    } catch {
      return defaultValue;
    }
  },
  getNumber: (key: string) => Number(storage?.getItem(key)),
  getBoolean: (key: string) => Boolean(storage?.getItem(key)),
  set: (key, value?) =>
    storage?.setItem(
      key,
      isObject(value) || Array.isArray(value) ? JSON.stringify(value) : String(value),
    ),
  remove: (key) => storage?.removeItem(key),
};
